"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fragment = exports.default = void 0;
var _client = require("@apollo/client");
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _getUnitTypeConsts = _interopRequireDefault(require("../constants/getUnitTypeConsts"));
var _Grid = _interopRequireDefault(require("./designsystem/Grid"));
var _AmountBathrooms = _interopRequireDefault(require("./svg/AmountBathrooms.svg"));
var _AmountBedrooms = _interopRequireDefault(require("./svg/AmountBedrooms.svg"));
var _AmountToilets = _interopRequireDefault(require("./svg/AmountToilets.svg"));
var _Code = _interopRequireDefault(require("./svg/Code.svg"));
var _LivingArea = _interopRequireDefault(require("./svg/LivingArea.svg"));
var _AccommodationLocation = _interopRequireDefault(require("./svg/AccommodationLocation.svg"));
var _MaxOccupancy = _interopRequireDefault(require("./svg/MaxOccupancy.svg"));
var _OutdoorArea = _interopRequireDefault(require("./svg/OutdoorArea.svg"));
var _PetsAllowed = _interopRequireDefault(require("./svg/PetsAllowed.svg"));
var _PetsProhibited = _interopRequireDefault(require("./svg/PetsProhibited.svg"));
var _theme = require("@oberoninternal/travelbase-ds/constants/theme");
var __jsx = _react.default.createElement;
const fragment = exports.fragment = (0, _client.gql)`
    fragment UnitFeatures on RentalUnit {
        livingArea
        outdoorArea
        maxOccupancy
        amountBedrooms
        petsAllowed
        amountBathrooms
        amountToilets
        code
        type
        accommodation {
            place
        }
    }
`;
const UnitFeatures = ({
  data: {
    livingArea,
    outdoorArea,
    maxOccupancy,
    amountBedrooms,
    petsAllowed,
    amountBathrooms,
    amountToilets,
    code,
    accommodation,
    type
  }
}) => {
  const intl = (0, _reactIntl.useIntl)();
  const unitTypeConsts = (0, _getUnitTypeConsts.default)(intl);
  return __jsx(Features, null, __jsx(Feature, {
    style: {
      textTransform: 'capitalize'
    }
  }, unitTypeConsts[type].icon, unitTypeConsts[type].translation), __jsx(Feature, null, __jsx(_AccommodationLocation.default, null), __jsx("div", {
    title: accommodation.place
  }, accommodation.place)), __jsx(Feature, null, __jsx(_MaxOccupancy.default, null), __jsx(_reactIntl.FormattedMessage, {
    id: "VX14ri",
    defaultMessage: [{
      "type": 2,
      "style": null,
      "value": "maxOccupancy"
    }, {
      "type": 0,
      "value": " "
    }, {
      "type": 6,
      "pluralType": "cardinal",
      "value": "maxOccupancy",
      "offset": 0,
      "options": {
        "one": {
          "value": [{
            "type": 0,
            "value": "persoon"
          }]
        },
        "other": {
          "value": [{
            "type": 0,
            "value": "personen"
          }]
        }
      }
    }],
    values: {
      maxOccupancy
    }
  })), petsAllowed ? __jsx(Feature, null, __jsx(_PetsAllowed.default, null), __jsx(_reactIntl.FormattedMessage, {
    id: "9ejMDT",
    defaultMessage: [{
      "type": 2,
      "style": null,
      "value": "petsAllowed"
    }, {
      "type": 0,
      "value": " "
    }, {
      "type": 6,
      "pluralType": "cardinal",
      "value": "petsAllowed",
      "offset": 0,
      "options": {
        "one": {
          "value": [{
            "type": 0,
            "value": "huisdier"
          }]
        },
        "other": {
          "value": [{
            "type": 0,
            "value": "huisdieren"
          }]
        }
      }
    }, {
      "type": 0,
      "value": " max."
    }],
    values: {
      petsAllowed
    }
  })) : __jsx(Feature, null, __jsx(_PetsProhibited.default, null), __jsx(_reactIntl.FormattedMessage, {
    id: "d61y3E",
    defaultMessage: [{
      "type": 0,
      "value": "Geen huisdieren"
    }]
  })), amountBathrooms !== null && __jsx(Feature, null, __jsx(_AmountBathrooms.default, null), __jsx(_reactIntl.FormattedMessage, {
    id: "fLVJiO",
    defaultMessage: [{
      "type": 2,
      "style": null,
      "value": "amountBathrooms"
    }, {
      "type": 0,
      "value": " "
    }, {
      "type": 6,
      "pluralType": "cardinal",
      "value": "amountBathrooms",
      "offset": 0,
      "options": {
        "one": {
          "value": [{
            "type": 0,
            "value": "badkamer"
          }]
        },
        "other": {
          "value": [{
            "type": 0,
            "value": "badkamers"
          }]
        }
      }
    }],
    values: {
      amountBathrooms
    }
  })), amountToilets !== null && __jsx(Feature, null, __jsx(_AmountToilets.default, null), __jsx(_reactIntl.FormattedMessage, {
    id: "RipvCs",
    defaultMessage: [{
      "type": 2,
      "style": null,
      "value": "amountToilets"
    }, {
      "type": 0,
      "value": " "
    }, {
      "type": 6,
      "pluralType": "cardinal",
      "value": "amountToilets",
      "offset": 0,
      "options": {
        "one": {
          "value": [{
            "type": 0,
            "value": "toilet"
          }]
        },
        "other": {
          "value": [{
            "type": 0,
            "value": "toiletten"
          }]
        }
      }
    }],
    values: {
      amountToilets
    }
  })), amountBedrooms !== null && __jsx(Feature, null, __jsx(_AmountBedrooms.default, null), __jsx(_reactIntl.FormattedMessage, {
    id: "VoDV6c",
    defaultMessage: [{
      "type": 2,
      "style": null,
      "value": "amountBedrooms"
    }, {
      "type": 0,
      "value": " "
    }, {
      "type": 6,
      "pluralType": "cardinal",
      "value": "amountBedrooms",
      "offset": 0,
      "options": {
        "one": {
          "value": [{
            "type": 0,
            "value": "slaapkamer"
          }]
        },
        "other": {
          "value": [{
            "type": 0,
            "value": "slaapkamers"
          }]
        }
      }
    }],
    values: {
      amountBedrooms
    }
  })), livingArea !== null && __jsx(Feature, null, __jsx(_LivingArea.default, null), __jsx(_reactIntl.FormattedMessage, {
    id: "tfO81f",
    defaultMessage: [{
      "type": 2,
      "style": null,
      "value": "livingArea"
    }, {
      "type": 0,
      "value": "m² binnenopp."
    }],
    values: {
      livingArea
    }
  })), (outdoorArea ?? 0) > 0 && __jsx(Feature, null, __jsx(_OutdoorArea.default, null), __jsx(_reactIntl.FormattedMessage, {
    id: "HnV+ZE",
    defaultMessage: [{
      "type": 2,
      "style": null,
      "value": "outdoorArea"
    }, {
      "type": 0,
      "value": "m² buitenopp."
    }],
    values: {
      outdoorArea
    }
  })), __jsx(Feature, null, __jsx(_Code.default, null), __jsx(_reactIntl.FormattedMessage, {
    id: "c6dXFQ",
    defaultMessage: [{
      "type": 0,
      "value": "Accommodatie "
    }, {
      "type": 1,
      "value": "code"
    }],
    values: {
      code
    }
  })));
};
const Features = (0, _styledComponents.default)(_Grid.default).withConfig({
  displayName: "UnitFeatures__Features",
  componentId: "sc-1gpr176-0"
})(["grid-row-gap:1.6rem;"]);
const Feature = _styledComponents.default.div.withConfig({
  displayName: "UnitFeatures__Feature",
  componentId: "sc-1gpr176-1"
})(["display:flex;align-items:center;grid-column:span 2;white-space:nowrap;", ";svg{min-width:2rem;min-height:2rem;margin-right:1rem;g path{stroke-width:1.3;}}> div{overflow:hidden;text-overflow:ellipsis;}@media screen and (max-width:", "){grid-column:span 4;}"], ({
  theme
}) => (0, _theme.getFontCss)(theme.fontSizes.body.regular), ({
  theme
}) => theme.mediaQueries.xs);
var _default = exports.default = UnitFeatures;