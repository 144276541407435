"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _Modal = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/Modal"));
var _react = _interopRequireWildcard(require("react"));
var _ModalHeader = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/ModalHeader"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _grid = require("@rebass/grid");
var _Spinner = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/figure/Spinner"));
var _getLogoForBrand = _interopRequireDefault(require("@oberoninternal/travelbase-ds/utils/getLogoForBrand"));
const _excluded = ["redirectUrl", "brand"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const brandNames = {
  ameland: 'Ameland',
  oberon: 'Oberon',
  schier: 'VVV Schiermonnikoog',
  terschelling: 'VVV Terschelling',
  texel: 'VVV Texel',
  waterland: 'VVV Waterland van Friesland'
};
const RedirectModal = _ref => {
  let {
      redirectUrl,
      brand
    } = _ref,
    rest = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  const timer = (0, _react.useRef)(null);
  const {
    onClose
  } = rest;
  (0, _react.useEffect)(() => {
    if (redirectUrl) {
      timer.current = setTimeout(() => {
        window.location.href = redirectUrl;
        onClose();
      }, 5000);
    }
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [redirectUrl, onClose]);
  const name = brandNames[brand];
  return __jsx(_Modal.default, (0, _extends2.default)({}, rest, {
    variant: "regular"
  }), __jsx(_grid.Flex, {
    flexDirection: "column",
    height: "100%"
  }, __jsx(_ModalHeader.default, {
    variant: "small"
  }, __jsx(_grid.Box, {
    width: brand === 'waterland' ? 328 : 164,
    mb: 2,
    mt: -4
  }, (0, _getLogoForBrand.default)())), __jsx(_grid.Flex, {
    flexDirection: "column",
    justifyContent: "center",
    flex: 1
  }, __jsx(_grid.Box, {
    mt: 4
  }, __jsx(_Body.default, {
    variant: "large"
  }, "Je maakt de reservering compleet via onze partner ", name, ".")), __jsx(_grid.Box, {
    mt: 4
  }, __jsx(_grid.Flex, {
    alignItems: "center"
  }, __jsx(_Spinner.default, {
    style: {
      minWidth: '24px',
      minHeight: '24px'
    }
  }), __jsx(_grid.Box, {
    ml: 4
  }, __jsx(_Body.default, {
    variant: "large"
  }, "Binnen enkele seconden sturen we je automatisch door naar het reserveringsformulier.")))))));
};
var _default = exports.default = RedirectModal;