"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _reactScroll = require("react-scroll");
var _react = _interopRequireWildcard(require("react"));
var _theme = require("@oberoninternal/travelbase-ds/constants/theme");
var _TenantContext = require("../../context/TenantContext");
const _excluded = ["items", "className"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const Nav = _ref => {
  let {
      items,
      className
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  const {
    0: active,
    1: setActive
  } = (0, _react.useState)(null);
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const scrollConfig = _objectSpread({
    smooth: true,
    spy: true,
    duration: 500,
    onSetActive(to) {
      setActive(to);
    },
    offset: brandConfig.navigation?.offset
  }, props);
  return __jsx(Inner, {
    className: className
  }, items.map((item, index) => __jsx(NavItem, {
    key: index,
    className: item.className
  }, __jsx(NavItemLink, (0, _extends2.default)({}, scrollConfig, {
    className: active === item.name || index === 0 && active === null ? 'active' : '',
    to: item.name
  }), item.label))));
};
var _default = exports.default = Nav;
const NavItem = _styledComponents.default.li.withConfig({
  displayName: "Nav__NavItem",
  componentId: "sc-1glxlj1-0"
})(["margin:0;"]);
const Inner = _styledComponents.default.ul.withConfig({
  displayName: "Nav__Inner",
  componentId: "sc-1glxlj1-1"
})(["display:flex;list-style:none;margin:0;padding:0;> ", " + ", "{margin-left:2.4rem;}@media screen and (min-width:1300px){> ", " + ", "{margin-left:4rem;}}"], NavItem, NavItem, NavItem, NavItem);
const NavItemLink = (0, _styledComponents.default)(_reactScroll.Link).withConfig({
  displayName: "Nav__NavItemLink",
  componentId: "sc-1glxlj1-2"
})(["color:", " !important;text-decoration:none !important;font-weight:600;cursor:pointer;position:relative;", ";&::before{position:absolute;content:'';left:0;bottom:0;right:0;opacity:0;border-bottom:1px solid ", ";padding-bottom:0.2rem;transform-origin:100% 0%;transform:scale3d(0,1,1);transition:transform 0.3s,opacity 0.3s;}&:hover{::before{opacity:0.4;transform-origin:0% 50%;transform:scale3d(1,1,1);}}&.active{color:", " !important;transition:color 0.3s ease-in-out;::before{opacity:1;transform-origin:0% 0%;transform:scale3d(1,1,1);}}"], ({
  theme
}) => theme.colors.neutral['60'], ({
  theme
}) => (0, _theme.getFontCss)(theme.fontSizes.body.small), ({
  theme
}) => theme.colors.neutral['50'], ({
  theme
}) => theme.colors.neutral['80']);