"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fragment = exports.default = void 0;
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _Img = _interopRequireDefault(require("./Img"));
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _grid = require("@rebass/grid");
var _client = require("@apollo/client");
var _link = _interopRequireDefault(require("next/link"));
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _createPlaceholder = _interopRequireDefault(require("../utils/createPlaceholder"));
var _ContentWrapper = _interopRequireDefault(require("./ContentWrapper"));
var _Heading = _interopRequireDefault(require("./designsystem/Heading"));
var _TextClamp = _interopRequireDefault(require("./designsystem/TextClamp"));
var _Stack = _interopRequireDefault(require("./Stack"));
var _router = require("next/router");
var __jsx = _react.default.createElement;
const fragment = exports.fragment = (0, _client.gql)`
    fragment UnitAccommodation on Accommodation {
        slug
        jpegMain: mainImage {
            transform(config: HERO, format: JPEG) {
                ...Transform
            }
        }
        webpMain: mainImage {
            transform(config: HERO, format: WEBP) {
                ...Transform
            }
        }
        descriptionGeneral
        name
    }
`;
const UnitAccommodation = ({
  data: {
    jpegMain,
    name,
    webpMain,
    descriptionGeneral,
    slug
  }
}) => {
  const {
    query: {
      searched
    }
  } = (0, _router.useRouter)();
  const renderAccommodationButton = className => __jsx(_link.default, {
    href: {
      pathname: `/complex/${slug}`,
      query: {
        searched
      }
    },
    passHref: true,
    legacyBehavior: true
  }, __jsx(_Button.default, {
    style: {
      display: 'inline-flex'
    },
    as: "a",
    className: className,
    variant: "outline"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "110NwM",
    defaultMessage: [{
      "type": 0,
      "value": "Bekijk "
    }, {
      "type": 1,
      "value": "name"
    }],
    values: {
      name
    }
  })));
  return __jsx(_ContentWrapper.default, null, __jsx(Wrapper, {
    flexDirection: ['column', null, null, 'row'],
    mb: [5, null, null, 0]
  }, jpegMain && webpMain && __jsx(_Stack.default, {
    spacing: 5,
    flex: 1,
    style: {
      display: 'block'
    }
  }, __jsx(ImageContainer, null, __jsx(_Img.default, {
    fallback: {
      src: jpegMain?.transform?.src
    },
    sources: [{
      srcSet: webpMain?.transform?.srcSet,
      type: 'image/webp'
    }, {
      srcSet: jpegMain?.transform?.srcSet,
      type: 'image/jpeg'
    }],
    placeholder: (0, _createPlaceholder.default)(webpMain?.transform?.placeholder),
    loading: "lazy",
    layout: "fill"
  })), renderAccommodationButton('gt-m')), __jsx(_grid.Box, {
    flex: 1,
    pt: 7
  }, __jsx(_Heading.default, null, __jsx(_Title.default, {
    variant: "large"
  }, name)), descriptionGeneral && __jsx(_TextClamp.default, {
    alwaysClamp: true,
    text: descriptionGeneral
  }))), renderAccommodationButton('lt-m'));
};
const Wrapper = (0, _styledComponents.default)(_grid.Flex).withConfig({
  displayName: "UnitAccommodation__Wrapper",
  componentId: "sc-109ezgi-0"
})(["@media screen and (min-width:", "){> div:nth-child(2){padding-left:8rem;}}@media screen and (min-width:", "){> div:nth-child(2){padding-left:15rem;}}"], ({
  theme
}) => theme.mediaQueries.m, ({
  theme
}) => theme.mediaQueries.xl);
const ImageContainer = _styledComponents.default.div.withConfig({
  displayName: "UnitAccommodation__ImageContainer",
  componentId: "sc-109ezgi-1"
})(["display:inline-block;width:100%;::after{padding-top:calc(9 / 16 * 100%);content:'';display:block;}position:relative;img{object-fit:cover;}"]);
var _default = exports.default = UnitAccommodation;