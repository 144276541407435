"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _Modal = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/Modal"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var __jsx = _react.default.createElement;
const MediaModal = ({
  children,
  open,
  onClose
}) => __jsx(StyledModal, {
  variant: "large",
  open: open,
  onClose: onClose
}, children);
const StyledModal = (0, _styledComponents.default)(_Modal.default).withConfig({
  displayName: "MediaModal__StyledModal",
  componentId: "sc-1lgud2u-0"
})(["height:100vh;> [data-reach-dialog-content]{display:flex;align-items:center;justify-content:center;height:100%;padding:8rem 6.8rem;@media (max-width:", "){height:100%;padding:8rem 2rem 2rem;}}"], ({
  theme
}) => theme.mediaQueries.s);
var _default = exports.default = MediaModal;