"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _MediaModal = _interopRequireDefault(require("./MediaModal"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _dynamic = _interopRequireDefault(require("next/dynamic"));
var __jsx = _react.default.createElement;
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const ReactPlayer = (0, _dynamic.default)(() => Promise.resolve().then(() => _interopRequireWildcard(require('react-player'))), {
  loadableGenerated: {
    webpack: () => [require.resolveWeak('react-player')]
  }
});
const VideoModal = ({
  open,
  onClose,
  src
}) => {
  const srcUrl = src ?? '';
  return __jsx(_MediaModal.default, {
    open: open,
    onClose: onClose
  }, __jsx(PlayerWrapper, null, __jsx(StyledReactPlayer, {
    url: srcUrl,
    width: "100%",
    height: "100%",
    controls: true
  })));
};
var _default = exports.default = VideoModal;
const PlayerWrapper = _styledComponents.default.div.withConfig({
  displayName: "VideoModal__PlayerWrapper",
  componentId: "f5ios0-0"
})(["position:relative;flex-grow:1;padding-top:56.25%;"]);
const StyledReactPlayer = (0, _styledComponents.default)(ReactPlayer).withConfig({
  displayName: "VideoModal__StyledReactPlayer",
  componentId: "f5ios0-1"
})(["position:absolute;top:0;left:0;"]);