"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUnitDetailSeoMessages = exports.getCompanySeoMessages = exports.getActivitySeoMessages = void 0;
var _reactIntl = require("react-intl");
var _getUnitTypeConsts = _interopRequireDefault(require("./getUnitTypeConsts"));
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */

const messages = (0, _reactIntl.defineMessages)({
  unitDetailTitle: {
    "id": "heRdGo",
    "defaultMessage": [{
      "type": 1,
      "value": "unitName"
    }]
  },
  unitDetailDescription: {
    "id": "MWJUvD",
    "defaultMessage": [{
      "type": 1,
      "value": "unitDescription"
    }]
  },
  companyTitle: {
    "id": "14V8s5",
    "defaultMessage": [{
      "type": 1,
      "value": "companyName"
    }]
  },
  companyDescription: {
    "id": "Zj1x9b",
    "defaultMessage": [{
      "type": 1,
      "value": "companyDescription"
    }]
  },
  activityTitle: {
    "id": "Rca0bb",
    "defaultMessage": [{
      "type": 1,
      "value": "activityTitle"
    }]
  },
  activityDescription: {
    "id": "4nSkX8",
    "defaultMessage": [{
      "type": 1,
      "value": "activityDescription"
    }]
  }
});
const getUnitDetailSeoMessages = (data, intl) => {
  const unitTypeConsts = (0, _getUnitTypeConsts.default)(intl);
  const type = data?.type ? unitTypeConsts[data.type].translation : '';
  return {
    title: intl.formatMessage(messages.unitDetailTitle, {
      unitName: data?.name,
      // yes, these are not used by the defaultMessage.
      // They are in use however in other projects (texel/teschelling) so DO NOT REMOVE THESE.
      type,
      tagline: data?.tagline,
      place: data?.accommodation.place
    }),
    description: intl.formatMessage(messages.unitDetailDescription, {
      unitDescription: data?.descriptionLayout,
      type,
      tagline: data?.tagline
    })
  };
};
exports.getUnitDetailSeoMessages = getUnitDetailSeoMessages;
const getCompanySeoMessages = (data, intl) => ({
  title: intl.formatMessage(messages.companyTitle, {
    companyName: data?.name,
    place: data?.place,
    categories: data?.categories.map(category => category.name).join(', ')
  }),
  description: intl.formatMessage(messages.companyDescription, {
    companyDescription: data?.description,
    companyShortDescription: data?.description?.slice(0, 155),
    name: data?.name,
    city: data?.address.city
  })
});
exports.getCompanySeoMessages = getCompanySeoMessages;
const getActivitySeoMessages = (data, intl) => ({
  title: intl.formatMessage(messages.activityTitle, {
    activityTitle: data?.name,
    place: data?.place,
    type: data?.themes.map(theme => theme.name).join(', ')
  }),
  description: intl.formatMessage(messages.activityDescription, {
    activityDescription: data?.description,
    activityShortDescription: data?.description?.slice(0, 155),
    name: data?.name,
    place: data?.place
  })
});
exports.getActivitySeoMessages = getActivitySeoMessages;