"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _ArrowRight = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/figure/ArrowRight"));
var _theme = require("@oberoninternal/travelbase-ds/constants/theme");
var _link = _interopRequireDefault(require("next/link"));
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _HSeperator = _interopRequireDefault(require("./svg/HSeperator.svg"));
const _excluded = ["active", "href", "className"];
var __jsx = _react.default.createElement;
const BreadCrumbs = ({
  children
}) => __jsx(Container, null, __jsx("ul", null, children));
const PrevLink = props => __jsx(PrevItem, null, __jsx(StyledArrowRight, {
  height: "10",
  style: {
    transform: 'rotate(180deg)'
  }
}), __jsx(_link.default, (0, _extends2.default)({}, props, {
  passHref: true,
  legacyBehavior: true
}), __jsx(_reactIntl.FormattedMessage, {
  id: "6HpM2b",
  defaultMessage: [{
    "type": 0,
    "value": "Terug"
  }]
})), __jsx(StyledHSeperator, {
  className: "gt-s"
}));
const isInternalLink = href => {
  if (typeof href !== 'string') {
    return true;
  }
  try {
    return !new URL(href);
  } catch (error) {
    return false;
  }
};
const Crumb = _ref => {
  let {
      active,
      href,
      className
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  const isInternal = isInternalLink(href);
  return __jsx(Item, {
    className: className
  }, active && props.children, !active && __jsx(_react.default.Fragment, null, !isInternal && __jsx("a", {
    href: href
  }, props.children), isInternal && href && __jsx(_link.default, (0, _extends2.default)({
    href: href
  }, props, {
    legacyBehavior: true
  }))), __jsx(StyledArrowRight, {
    height: "10"
  }));
};
const Container = _styledComponents.default.div.withConfig({
  displayName: "Breadcrumbs__Container",
  componentId: "sc-1xedaf6-0"
})(["max-width:calc(140.8rem + var(--wrapperPadding) * 2);padding:0 var(--wrapperPadding);margin:0 auto;> ul{align-items:center;margin-bottom:0;margin-top:1.8rem;padding:0;display:flex;}li{align-items:center;display:flex;", ";:last-of-type{svg{display:none;}}}"], ({
  theme
}) => (0, _theme.getFontCss)(theme.fontSizes.body.small));
var _default = exports.default = Object.assign(BreadCrumbs, {
  PrevLink,
  Crumb
});
const Item = _styledComponents.default.li.withConfig({
  displayName: "Breadcrumbs__Item",
  componentId: "sc-1xedaf6-1"
})(["color:", ";a{font-weight:400;color:", ";text-decoration:none !important;:hover{text-decoration:underline !important;}}"], ({
  theme
}) => theme.colors.neutral[40], ({
  theme
}) => theme.colors.neutral[60]);
const PrevItem = (0, _styledComponents.default)(Item).withConfig({
  displayName: "Breadcrumbs__PrevItem",
  componentId: "sc-1xedaf6-2"
})(["a{color:", ";font-weight:600;}"], ({
  theme
}) => theme.colors.neutral[90]);
const StyledArrowRight = (0, _styledComponents.default)(_ArrowRight.default).withConfig({
  displayName: "Breadcrumbs__StyledArrowRight",
  componentId: "sc-1xedaf6-3"
})(["margin:0 ", ";"], ({
  theme
}) => theme.spacing['30_Small']);
const StyledHSeperator = (0, _styledComponents.default)(_HSeperator.default).withConfig({
  displayName: "Breadcrumbs__StyledHSeperator",
  componentId: "sc-1xedaf6-4"
})(["margin:0 1.2rem;"]);