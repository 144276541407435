"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fragment = exports.default = void 0;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = _interopRequireDefault(require("react"));
var _Cell = _interopRequireDefault(require("./designsystem/Cell"));
var _Grid = _interopRequireDefault(require("./designsystem/Grid"));
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Heading = _interopRequireDefault(require("./designsystem/Heading"));
var _AttributeTile = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/misc/AttributeTile"));
var _AttributeGrid = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/AttributeGrid"));
var _TextClamp = _interopRequireDefault(require("./designsystem/TextClamp"));
var _client = require("@apollo/client");
var _ContentWrapper = _interopRequireDefault(require("./ContentWrapper"));
var _TenantContext = require("../context/TenantContext");
const _excluded = ["data"];
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const fragment = exports.fragment = (0, _client.gql)`
    fragment Extras on AttributeCategory {
        name
        handle
        attributes {
            id
            name
            iconUrl
            handle
        }
    }
`;
const getUnitExtras = attributeCategories => attributeCategories.map(attributeCategory => _objectSpread(_objectSpread({}, attributeCategory), {}, {
  attributes: attributeCategory.attributes.filter(attribute => attribute.iconUrl !== null)
}));
const Extras = _ref => {
  let {
      data
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  const intl = (0, _reactIntl.useIntl)();
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const extrasWithIcon = getUnitExtras(data);
  let {
    description
  } = props;
  const tiles = extrasWithIcon.map(attributeCategory => attributeCategory.attributes.map(attribute => __jsx(_AttributeTile.default, {
    key: attribute.name,
    title: attribute.name,
    label: attributeCategory.name,
    icon: attribute.iconUrl && __jsx("img", {
      style: {
        filter: brandConfig.unitExras?.svgFilter
      },
      src: attribute.iconUrl,
      alt: `${attribute.name} icon`
    })
  }))).flat();

  // Yes this is depending on a hardcoded attribute, but it's not going to change they said and only applies to Texel (obviously)
  if (extrasWithIcon.some(category => category.attributes.some(attribute => attribute.handle === 'zotexels'))) {
    description = description.concat(`\n\n${intl.formatMessage({
      "id": "23W8bZ",
      "defaultMessage": [{
        "type": 0,
        "value": "Dit is een ‘ZoTexels’ accommodatie. 'ZoTexels' is het kenmerk van accommodaties van particuliere verhuurders. De authentieke sfeer en échte Texelse gastvrijheid staat hier centraal."
      }]
    })}`);
  }
  // Yes this is depending on a hardcoded attribute, but it's not going to change they said and only applies to Texel (obviously)
  if (extrasWithIcon.some(category => category.attributes.some(attribute => attribute.handle === 'book-local'))) {
    description = description.concat(`\n\n${intl.formatMessage({
      "id": "0dXSYi",
      "defaultMessage": [{
        "type": 0,
        "value": "Op Texel zorgen we graag goed voor onze bezoekers en voor elkaar. Texelse ondernemers gebruiken lokale producten en diensten om jouw verblijf onvergetelijk en ‘echt Texels’ te maken. Via VVV Texel boeken betekent dat jouw geld bij Texelse bedrijven terecht komt en wordt besteed aan het uitbouwen en promoten van de unieke en lokale Texelse economie."
      }]
    })}`);
  }
  return __jsx(_ContentWrapper.default, null, __jsx(_Grid.default, null, tiles.length > 0 ? __jsx(_react.default.Fragment, null, __jsx(DescriptionCell, {
    pb: 22,
    gridColumn: ['1 / -1', null, null, '8 / 11', null, '8 / 12']
  }, __jsx(_Heading.default, null, __jsx(_Title.default, {
    variant: "large"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "2z4Jm5",
    defaultMessage: [{
      "type": 0,
      "value": "Bijzonder aan deze accommodatie"
    }]
  }))), __jsx(_TextClamp.default, {
    text: description
  })), __jsx(TilesCell, {
    gridColumn: ['1 / -1', null, null, '1 / 7']
  }, __jsx(_AttributeGrid.default, null, tiles))) : __jsx(Content, null, __jsx(_Heading.default, null, __jsx(_Title.default, {
    variant: "large"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "2z4Jm5",
    defaultMessage: [{
      "type": 0,
      "value": "Bijzonder aan deze accommodatie"
    }]
  }))), __jsx(_TextClamp.default, {
    maxLength: 200,
    text: description,
    variant: "regular"
  }))));
};
const Content = _styledComponents.default.div.withConfig({
  displayName: "Extras__Content",
  componentId: "xswtjv-0"
})(["grid-column:1 / -1;max-width:80.8rem;> * + *{margin-top:", ";@media (min-width:", "){margin-top:", ";}}"], ({
  theme
}) => theme.spacing['40_Standard'], ({
  theme
}) => theme.mediaQueries.l, ({
  theme
}) => theme.spacing['50_Semi']);
const DescriptionCell = (0, _styledComponents.default)(_Cell.default).withConfig({
  displayName: "Extras__DescriptionCell",
  componentId: "xswtjv-1"
})(["order:1;align-self:center;@media (min-width:", "){order:2;}"], ({
  theme
}) => theme.mediaQueries.m);
const TilesCell = (0, _styledComponents.default)(_Cell.default).withConfig({
  displayName: "Extras__TilesCell",
  componentId: "xswtjv-2"
})(["order:2;@media (min-width:", "){order:1;}"], ({
  theme
}) => theme.mediaQueries.m);
var _default = exports.default = Extras;