"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getServerSideProps = exports.default = exports.UnitSection = void 0;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _CircleView = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/figure/CircleView"));
var _Play = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/figure/Play"));
var _useSesame = _interopRequireDefault(require("@oberoninternal/travelbase-ds/hooks/useSesame"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _lodash = _interopRequireDefault(require("lodash.isequal"));
var _router = require("next/router");
var _queryString = require("query-string");
var _react = _interopRequireWildcard(require("react"));
var _reactIntl = require("react-intl");
var _reactSchemaorg = require("react-schemaorg");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _useQueryParams = require("use-query-params");
var _affiliate = require("../../constants/affiliate");
var _baseUrls = _interopRequireDefault(require("../../constants/baseUrls"));
var _bookingParams = _interopRequireDefault(require("../../constants/bookingParams"));
var _getUnitTypeConsts = _interopRequireDefault(require("../../constants/getUnitTypeConsts"));
var _searchParams = _interopRequireDefault(require("../../constants/searchParams"));
var _seoMessages = require("../../constants/seoMessages");
var _TenantContext = require("../../context/TenantContext");
var _createApolloClient = require("../../createApolloClient");
var _Booking = require("../../entities/Booking");
var _NotFoundError = _interopRequireDefault(require("../../entities/NotFoundError"));
var _useAnalyticsContentCategory = _interopRequireDefault(require("../../hooks/analytics/useAnalyticsContentCategory"));
var _useAnalyticsUnitView = _interopRequireDefault(require("../../hooks/analytics/useAnalyticsUnitView"));
var _useCart = require("../../hooks/useCart");
var _useQueryParams2 = _interopRequireDefault(require("../../hooks/useQueryParams"));
var _useUnitDetailData = _interopRequireDefault(require("../../hooks/useUnitDetailData"));
var _useUnitSchema = _interopRequireDefault(require("../../hooks/useUnitSchema"));
var _cart = require("../../utils/cart");
var _createImgProps = _interopRequireDefault(require("../../utils/createImgProps"));
var _createPlaceholder = _interopRequireDefault(require("../../utils/createPlaceholder"));
var _createUnitTitle = _interopRequireDefault(require("../../utils/createUnitTitle"));
var _getDefaultAccommodationType = _interopRequireDefault(require("../../utils/getDefaultAccommodationType"));
var _groupImagesByCategory = require("../../utils/groupImagesByCategory");
var _search = require("../../utils/search");
var _trip = require("../../utils/trip");
var _ContentWrapper = _interopRequireDefault(require("../ContentWrapper"));
var _BookingBar = _interopRequireDefault(require("../designsystem/BookingBar"));
var _GradientBackground = _interopRequireDefault(require("../designsystem/GradientBackground"));
var _Grid = _interopRequireDefault(require("../designsystem/Grid"));
var _Hero = _interopRequireWildcard(require("../Hero"));
var _MediaButton = _interopRequireDefault(require("../designsystem/MediaButton"));
var _PhotoModal = _interopRequireDefault(require("../designsystem/PhotoModal"));
var _Section = _interopRequireDefault(require("../designsystem/Section"));
var _Divider = require("../Divider");
var _Extras = _interopRequireDefault(require("../Extras"));
var _FullCircleModal = _interopRequireDefault(require("../FullCircleModal"));
var _HeroSearchBar = _interopRequireDefault(require("../HeroSearchBar"));
var _LoadingDetail = _interopRequireDefault(require("../LoadingDetail"));
var _Location = _interopRequireDefault(require("../Location"));
var _Page = _interopRequireDefault(require("../Page"));
var _UnitAccommodation = _interopRequireDefault(require("../UnitAccommodation"));
var _UnitAlternatives = _interopRequireDefault(require("../UnitAlternatives"));
var _UnitAttributes = _interopRequireDefault(require("../UnitAttributes"));
var _UnitAvailability = _interopRequireDefault(require("../UnitAvailability"));
var _UnitBreadCrumbs = _interopRequireDefault(require("../UnitBreadCrumbs"));
var _UnitIntro = _interopRequireDefault(require("../UnitIntro"));
var _UnitLocationDescription = _interopRequireDefault(require("../UnitLocationDescription"));
var _UnitOwner = _interopRequireDefault(require("../UnitOwner"));
var _UnitParticulars = _interopRequireDefault(require("../UnitParticulars"));
var _UnitPhotos = _interopRequireDefault(require("../UnitPhotos"));
var _UnitRelevantAlternatives = _interopRequireDefault(require("../UnitRelevantAlternatives"));
var _UnitReviews = _interopRequireDefault(require("../UnitReviews"));
var _UspsContent = _interopRequireDefault(require("../UspsContent"));
var _VideoModal = _interopRequireDefault(require("../VideoModal"));
var _lodash2 = _interopRequireDefault(require("lodash.pick"));
var _head = _interopRequireDefault(require("next/head"));
var _RedirectModal = _interopRequireDefault(require("../RedirectModal"));
var _useGetPageSlugByLocale = _interopRequireDefault(require("../../hooks/useGetPageSlugByLocale"));
var _usePrevious = _interopRequireDefault(require("../../hooks/usePrevious"));
const _excluded = ["trip"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const someAmountIsDefined = (...args) => args.some(arg => typeof arg === 'number');
const UnitDetail = ({
  pageSlug
}) => {
  const {
    setCart
  } = (0, _useCart.useSetCart)();
  const [urlParams] = (0, _useQueryParams2.default)(_objectSpread(_objectSpread({}, _searchParams.default), {}, {
    withHero: (0, _useQueryParams.withDefault)(_useQueryParams.BooleanParam, false),
    searched: _useQueryParams.StringParam
  }));
  const searchedParams = urlParams.searched ? (0, _useQueryParams.decodeQueryParams)(_searchParams.default, (0, _queryString.parse)(urlParams.searched)) : null;
  const paramsInput = (0, _react.useMemo)(() => (0, _trip.convertBookingToParamsInput)(urlParams), [urlParams]);
  (0, _useAnalyticsContentCategory.default)('rentalUnit');
  const intl = (0, _reactIntl.useIntl)();
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const {
    UnitInfo
  } = brandConfig.customContent ?? {};
  const {
    accommodationType = (0, _getDefaultAccommodationType.default)(brandConfig),
    withHero
  } = urlParams;
  const {
    query: params,
    push
  } = (0, _router.useRouter)();
  const {
    data,
    loading,
    loadingAlternatives,
    loadingInitialTrip
  } = (0, _useUnitDetailData.default)(params.slug, paramsInput);
  const {
    0: reserving,
    1: setReserving
  } = (0, _react.useState)(false);

  // Modals
  const {
    open: videoModalIsOpen,
    onOpen: onOpenVideoModal,
    onClose: onCloseVideoModal
  } = (0, _useSesame.default)();
  const {
    open: fullCircleModalIsOpen,
    onOpen: onOpenFullCircleModal,
    onClose: onCloseFullCircleModal
  } = (0, _useSesame.default)();
  const {
    open: plannerModalOpen,
    onOpen: onOpenPlannerModal,
    onClose: onClosePlannerModal
  } = (0, _useSesame.default)();
  const {
    open: redirectModalOpen,
    onOpen: onOpenRedirectModal,
    onClose: onCloseRedirectModal
  } = (0, _useSesame.default)();
  const {
    0: redirectModalProps,
    1: setRedirectModalProps
  } = (0, _react.useState)({});
  const {
    open: openPhotos,
    onClose: onClosePhotos,
    onOpen: onOpenPhotos
  } = (0, _useSesame.default)();
  const {
    0: startIndex,
    1: setStartIndex
  } = (0, _react.useState)(0);
  const rentalUnit = data?.rentalUnit;
  const [bookingParams, setParams] = (0, _useQueryParams2.default)(_objectSpread(_objectSpread({}, _bookingParams.default), {}, {
    specialId: _useQueryParams.StringParam
  }));
  const {
    0: booking,
    1: setBooking
  } = (0, _react.useState)(bookingParams);
  const onBookingChange = (0, _react.useCallback)(newBooking => {
    const {
        trip
      } = newBooking,
      rest = (0, _objectWithoutProperties2.default)(newBooking, _excluded);
    setBooking(current => {
      const mergedBooking = _objectSpread(_objectSpread({}, current), newBooking);
      if ((0, _lodash.default)(current, mergedBooking)) {
        // bail on update if there's nothing to update
        return current;
      }

      // we reflect the params in the url as well
      setParams(_objectSpread(_objectSpread({}, rest), {}, {
        specialId: trip?.specialId ?? undefined
      }), 'replaceIn');
      return mergedBooking;
    });
  }, [setParams]);
  const prevSlug = (0, _usePrevious.default)(params.slug);
  (0, _react.useEffect)(() => {
    if (prevSlug !== params.slug) {
      setBooking(_objectSpread({}, bookingParams));
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [params.slug]);
  (0, _react.useEffect)(() => {
    if (data.initialTrip) {
      setBooking(current => _objectSpread(_objectSpread({}, current), {}, {
        trip: data.initialTrip
      }));
    }
  }, [data.initialTrip]);
  (0, _react.useEffect)(() => {
    if (!someAmountIsDefined(booking.amountAdults, booking.amountChildren, booking.amountYouths) && data.rentalUnit) {
      setBooking(current => _objectSpread(_objectSpread({}, current), {}, {
        amountAdults: data.rentalUnit?.minOccupancy
      }));
    }
  }, [booking, data.rentalUnit, onBookingChange]);
  const onBookingReservation = (0, _react.useCallback)(async () => {
    setReserving(true);
    const bookingValues = (0, _trip.withDefaultBookingValues)(booking);
    const {
      trip
    } = bookingValues;
    if (!trip || !data?.rentalUnit?.id) {
      setReserving(false);
      return;
    }
    const input = _objectSpread(_objectSpread({}, (0, _lodash2.default)(bookingValues, _Booking.bookingCartKeys)), {}, {
      cancellationInsurance: data.rentalUnit.cancellationInsuranceAvailable ? brandConfig.cancellationInsuranceDefault : false,
      contentsInsurance: false,
      optionalSurcharges: [],
      rentalUnitId: data?.rentalUnit?.id,
      specialId: trip.specialId
    });
    if (brandConfig.checkout?.checkoutShouldRedirect && !!rentalUnit?.brand) {
      const affiliateHandleCookie = _jsCookie.default.get(_affiliate.TRAVELBASE_AFF);
      let affiliateHandle = _jsCookie.default.get(_affiliate.TRAVELBASE_AFF);
      let affiliateReference = _jsCookie.default.get(_affiliate.TRAVELBASE_AFF_REFERENCE);
      if (brandConfig.forceAffiliate) {
        affiliateReference = undefined;

        // "Er nog geen affiliate (travelbase_aff) in de cookie zit, dan de force affiliate als affiliateHandle meegeven bij de checkout (zonder affiliateReference)"
        if (!affiliateHandleCookie) {
          affiliateHandle = brandConfig.forceAffiliate;
        }

        // "Er wel een affiliate in de cookie zit, maar die niet overeen komt met de force affiliate, dan de force affiliate doorgeven aan de checkout (zonder affiliateReference)"
        if (affiliateHandleCookie && affiliateHandleCookie !== brandConfig.forceAffiliate) {
          affiliateHandle = brandConfig.forceAffiliate;
        }

        // "Als dezelfde force affiliate al bestaat, dan deze ook doorgeven aan de checkout, samen met de bestaande travelbase_aff_reference als affiliateReference doorgeven aan de checkout."
        if (affiliateHandleCookie && affiliateHandleCookie === brandConfig.forceAffiliate) {
          affiliateReference = _jsCookie.default.get(_affiliate.TRAVELBASE_AFF_REFERENCE);
        }
      }
      const query = {};
      if (affiliateReference) {
        query.affiliateReference = affiliateReference;
      }
      if (affiliateHandle) {
        query.affiliateHandle = affiliateHandle;
      }
      const redirectUrl = (0, _queryString.stringifyUrl)({
        url: `${_baseUrls.default[rentalUnit.brand]}/checkout/redirect`,
        query: _objectSpread(_objectSpread({
          specialId: trip.specialId ?? undefined,
          rentalUnitSlug: rentalUnit?.slug
        }, query), params)
      });
      setRedirectModalProps({
        brand: rentalUnit.brand,
        redirectUrl
      });
      onOpenRedirectModal();

      // redirecting - don't set cart, that happens over on the redirected site.
      setReserving(false);
      return;
    }
    try {
      const result = await setCart({
        bookings: [input]
      });
      const {
        id: cartId
      } = result?.data?.updateCart.cart ?? {};
      if (!cartId) {
        // TODO: https://teamoberon.atlassian.net/browse/T0-1266
        throw new Error('Something went wrong');
      }
      (0, _cart.setCartId)(cartId);
      await push('/checkout/extras');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setReserving(false);
    }
  }, [booking, data.rentalUnit?.id, data.rentalUnit?.cancellationInsuranceAvailable, brandConfig.cancellationInsuranceDefault, brandConfig.checkout?.checkoutShouldRedirect, brandConfig.forceAffiliate, rentalUnit?.brand, rentalUnit?.slug, params, onOpenRedirectModal, setCart, push]);
  const unitSchema = (0, _useUnitSchema.default)(rentalUnit);
  (0, _useAnalyticsUnitView.default)(rentalUnit, booking);
  const {
    getPageSlugByLocale
  } = (0, _useGetPageSlugByLocale.default)();
  const expectedSlug = getPageSlugByLocale('ACCOMMODATION');
  if (pageSlug !== expectedSlug) {
    throw new _NotFoundError.default(`slug "${pageSlug}" does not match expected "${expectedSlug}"`);
  }
  if (loading) {
    return __jsx(_LoadingDetail.default, {
      withHero: withHero
    });
  }
  if (!rentalUnit) {
    throw new _NotFoundError.default();
  }
  const {
    name,
    reviewStats,
    accommodation: {
      name: accommodationName,
      hasPublicPage,
      ownerIntroduction,
      jpegMain,
      webpMain,
      descriptionGeneral,
      slug: accommodationSlug,
      jpegOwner,
      webpOwner,
      ownerName,
      ownerTips
    },
    jpegThumbnail,
    webpThumbnail,
    webpHero,
    jpegHero,
    maxOccupancy,
    minOccupancy,
    petsAllowed,
    type,
    fullCircleImageUrl,
    videoUrl,
    hasBakedInFilterProperty,
    firstTripDate
  } = rentalUnit;
  const mergedImages = rentalUnit.images.concat(rentalUnit.accommodation.images);
  const renderHero = (webp, jpeg, className) => __jsx(_Hero.default, {
    ratio: 9 / 16,
    className: className,
    placeholder: (0, _createPlaceholder.default)(webp.transform?.placeholder),
    sources: [{
      srcSet: webp.transform?.srcSet,
      type: 'image/webp'
    }, {
      srcSet: jpeg.transform?.srcSet,
      type: 'image/jpeg'
    }],
    fallback: {
      src: jpeg.transform?.src,
      alt: rentalUnit?.tagline
    }
  }, __jsx(_Hero.HeroButtonsMobile, null, videoUrl && __jsx(_MediaButton.default, {
    icon: __jsx(_Play.default, null),
    onClick: () => onOpenVideoModal()
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "kBJUtE",
    defaultMessage: [{
      "type": 0,
      "value": "Video"
    }]
  })), fullCircleImageUrl && __jsx(_MediaButton.default, {
    icon: __jsx(_CircleView.default, null),
    onClick: () => onOpenFullCircleModal()
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "zFCeP1",
    defaultMessage: [{
      "type": 0,
      "value": "360°"
    }]
  })), mergedImages.length > 0 && __jsx(_Hero.HeroPhotosButton, {
    onClick: onOpenPhotos
  }, mergedImages.length)), (videoUrl || fullCircleImageUrl) && __jsx(_Hero.HeroButtonsDesktop, null, videoUrl && __jsx(_MediaButton.default, {
    icon: __jsx(_Play.default, null),
    onClick: () => onOpenVideoModal()
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "tmQeUp",
    defaultMessage: [{
      "type": 0,
      "value": "Video bekijken"
    }]
  })), fullCircleImageUrl && __jsx(_MediaButton.default, {
    icon: __jsx(_CircleView.default, null),
    onClick: () => onOpenFullCircleModal(),
    style: {
      marginLeft: '4px'
    }
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "GM5ev0",
    defaultMessage: [{
      "type": 0,
      "value": "360° bekijken"
    }]
  }))));
  const hasAlternatives = rentalUnit.alternativesInAccommodation && rentalUnit.alternativesInAccommodation.hits.length > 0;
  const {
    title,
    description
  } = (0, _seoMessages.getUnitDetailSeoMessages)(rentalUnit, intl);
  return __jsx(_react.default.Fragment, null, __jsx(_head.default, null, __jsx("title", null, `${title} - ${brandConfig.seo.siteName}`), __jsx("meta", {
    name: "description",
    content: description
  }), __jsx("meta", {
    property: "og:title",
    content: title
  }), __jsx("meta", {
    property: "og:description",
    content: description
  }), __jsx("meta", {
    property: "og:image",
    content: rentalUnit?.jpegThumbnail?.transform?.src
  }), __jsx("meta", {
    property: "og:image:alt",
    content: rentalUnit?.tagline
  }), __jsx("meta", {
    name: "twitter:card",
    content: "summary_large_image"
  }), __jsx("meta", {
    name: "twitter:title",
    content: title
  }), __jsx("meta", {
    name: "twitter:description",
    content: description
  }), __jsx("meta", {
    name: "twitter:image",
    content: rentalUnit?.jpegThumbnail?.transform?.src
  }), __jsx("meta", {
    name: "twitter:image:alt",
    content: rentalUnit?.tagline
  }), brandConfig.bakedInFilterProperty && !hasBakedInFilterProperty &&
  // hide from search engines if we're using a baked-in property and the rentalUnit does not have said property.
  __jsx("meta", {
    name: "robots",
    content: "noindex"
  }), __jsx("script", (0, _reactSchemaorg.jsonLdScriptProps)(unitSchema))), __jsx(StyledPage, null, videoUrl && __jsx(_VideoModal.default, {
    open: videoModalIsOpen,
    onClose: onCloseVideoModal,
    src: videoUrl
  }), fullCircleImageUrl && __jsx(_FullCircleModal.default, {
    open: fullCircleModalIsOpen,
    onClose: onCloseFullCircleModal,
    src: fullCircleImageUrl
  }), redirectModalProps.brand && redirectModalProps.redirectUrl && __jsx(_RedirectModal.default, {
    brand: redirectModalProps.brand,
    redirectUrl: redirectModalProps.redirectUrl,
    onClose: onCloseRedirectModal,
    open: redirectModalOpen
  }), __jsx(_Hero.HeroContainer, null, __jsx(_HeroSearchBar.default, {
    hasHero: !!(webpHero && jpegHero),
    initialValues: {
      accommodationType,
      booking: searchedParams ?? (0, _trip.pickBookingFromParams)(urlParams)
    }
  }), webpHero && jpegHero ? renderHero(webpHero, jpegHero) : renderHero(webpThumbnail, jpegThumbnail, 'lt-s')), __jsx(_BookingBar.default, {
    reserving: reserving,
    booking: booking,
    onBookingReservation: onBookingReservation,
    plannerModalIsOpen: plannerModalOpen,
    openPlannerModal: onOpenPlannerModal,
    closePlannerModal: onClosePlannerModal,
    loading: loadingInitialTrip,
    data: {
      type: 'unit',
      rating: reviewStats?.count ? reviewStats?.average : undefined,
      title: name,
      strapline: hasPublicPage && accommodationName || undefined,
      thumbnail: {
        loading: 'eager',
        sources: [{
          srcSet: webpThumbnail.transform?.srcSet,
          type: 'image/webp'
        }, {
          srcSet: jpegThumbnail.transform?.srcSet,
          type: 'image/jpeg'
        }],
        fallback: {
          src: jpegThumbnail.transform?.src
        },
        placeholder: (0, _createPlaceholder.default)(webpThumbnail.transform?.placeholder)
      },
      owner: ownerIntroduction
    }
  }), __jsx(_Section.default, {
    name: "intro"
  }, __jsx(_UnitBreadCrumbs.default, {
    data: rentalUnit
  }), __jsx(_UnitIntro.default, {
    data: rentalUnit
  }), UnitInfo && __jsx(UnitInfo, null), __jsx(_ContentWrapper.default, null, mergedImages.length > 0 && __jsx(_UnitPhotos.default, {
    data: mergedImages,
    onCategoryClick: categoryIndex => {
      onOpenPhotos();
      setStartIndex(categoryIndex);
    },
    onOpenPhotos: () => {
      onOpenPhotos();
    }
  })), __jsx(_PhotoModal.default, {
    imagesOrCategories: (0, _groupImagesByCategory.groupImageByCategoryClean)(mergedImages, true),
    open: openPhotos,
    onClose: onClosePhotos,
    startIndex: startIndex
  }), __jsx(_UnitAttributes.default, {
    data: rentalUnit.attributeCategories,
    name: (0, _getUnitTypeConsts.default)(intl)[type].translation,
    registrationNumber: rentalUnit.municipalRegistration
  }), hasAlternatives && __jsx(_UnitAlternatives.default, {
    rentalUnit: rentalUnit,
    loading: loadingAlternatives
  }), !hasAlternatives && hasPublicPage && __jsx(_UnitAccommodation.default, {
    data: {
      jpegMain,
      webpMain,
      descriptionGeneral,
      name: accommodationName,
      slug: accommodationSlug
    }
  })), __jsx(UnitSection, {
    name: "location"
  }, __jsx(_Location.default, {
    data: rentalUnit.accommodation
  })), rentalUnit.descriptionExtras && __jsx(UnitSection, {
    name: "extras"
  }, __jsx(_Extras.default, {
    data: rentalUnit.attributeCategories,
    description: rentalUnit.descriptionExtras
  })), __jsx(_Divider.SectionDivider, null), __jsx(UnitSection, {
    name: "particulars"
  }, __jsx(_UnitParticulars.default, {
    data: rentalUnit
  })), __jsx(StyledSectionDivider, null), __jsx(UnitSection, {
    name: "price"
  }, __jsx(_UnitAvailability.default, {
    reserving: reserving,
    booking: booking,
    onBookingChange: onBookingChange,
    onBookingReservation: onBookingReservation,
    id: rentalUnit.id,
    slug: params.slug,
    open: plannerModalOpen,
    onClose: onClosePlannerModal,
    onOpen: onOpenPlannerModal,
    name: (0, _createUnitTitle.default)(rentalUnit.name, accommodationName, hasPublicPage),
    minOccupancy: minOccupancy,
    maxOccupancy: maxOccupancy,
    petsAllowed: petsAllowed,
    firstTripDate: firstTripDate ? new Date(firstTripDate) : undefined,
    type: "unit",
    loading: loadingInitialTrip
  })), __jsx(_Section.default, {
    name: `${rentalUnit.reviewStats ? 'reviews' : 'owner'}`
  }, rentalUnit.reviewStats && __jsx(ReviewsGradient, null), !rentalUnit.reviewStats && __jsx(_Divider.SectionDivider, null), __jsx(_ContentWrapper.default, null, __jsx(_Grid.default, null, rentalUnit.reviewStats && __jsx(_UnitReviews.default, {
    reviews: rentalUnit.reviews ?? [],
    reviewStats: rentalUnit.reviewStats,
    slug: params.slug,
    ownerName: ownerName,
    ownerImage: jpegOwner && webpOwner && (0, _createImgProps.default)(jpegOwner, webpOwner)
  }), (ownerIntroduction || ownerTips) && rentalUnit ? __jsx(_UnitOwner.default, {
    data: rentalUnit.accommodation,
    hasReviews: !!rentalUnit.reviewStats?.count
  }) : __jsx(_UnitLocationDescription.default, {
    brand: rentalUnit.brand,
    hasReviews: !!rentalUnit.reviewStats?.count
  })))), __jsx(_UnitRelevantAlternatives.default, {
    params: _objectSpread(_objectSpread({}, paramsInput), {}, {
      filters: (0, _search.convertToFilters)([(0, _search.getAccommodationFilters)(accommodationType), 'OR'])
    })
  })), __jsx(_UspsContent.default, null));
};
const getServerSideProps = async (brandConfig, ctx) => {
  // const { query, params, resolvedUrl, locale } = ctx;
  const {
    locale
  } = ctx;

  // setCanonicalLink(req, res);

  const client = (0, _createApolloClient.initApolloClient)(locale);

  // const decodedParams = decodeQueryParams(searchParams as QueryParamConfigMap, query);
  //
  // const slug = params?.slug as string;
  //
  // // redirect legacy slugs
  // if (slug.includes('_')) {
  //     return {
  //         redirect: {
  //             permanent: true,
  //             destination: resolvedUrl.replace(slug, slug.replace(/_/g, '-')),
  //         },
  //     };
  // }
  //
  // const result = await client.query<UnitDetailsQuery, UnitDetailsQueryVariables>({
  //     query: UnitDetailsDocument,
  //     variables: {
  //         slug,
  //         arrivalDate: decodedParams.arrivalDate ?? '',
  //         bakedInFilterProperty: brandConfig.bakedInFilterProperty ?? '',
  //     },
  // });
  //
  // // force react to create a new instance of the component if its rentalUnit id is different instead of using the same one
  // // When using the same, images glitch briefly by using stale data.
  // const props: { id?: string } = {};
  //
  // if (result.data.rentalUnit) {
  //     props.id = result.data.rentalUnit.id;
  // } else {
  //     return {
  //         notFound: true,
  //     };
  // }

  return (0, _createApolloClient.addApolloState)(client, {
    props: {}
  });
};
exports.getServerSideProps = getServerSideProps;
const ReviewsGradient = (0, _styledComponents.default)(_GradientBackground.default).attrs({
  variant: 'neutral'
}).withConfig({
  displayName: "UnitDetail__ReviewsGradient",
  componentId: "zfbbxd-0"
})(["@media (min-width:", "){:before{width:calc((100vw - 152rem) / 2 + 81.8rem);}}"], ({
  theme
}) => theme.mediaQueries.m);
const StyledPage = (0, _styledComponents.default)(_Page.default).withConfig({
  displayName: "UnitDetail__StyledPage",
  componentId: "zfbbxd-1"
})(["padding-bottom:8rem;"]);
const UnitSection = exports.UnitSection = (0, _styledComponents.default)(_Section.default).withConfig({
  displayName: "UnitDetail__UnitSection",
  componentId: "zfbbxd-2"
})(["--sectionPadding:min(8rem,var(--wrapperPadding));"]);
const StyledSectionDivider = (0, _styledComponents.default)(_Divider.SectionDivider).withConfig({
  displayName: "UnitDetail__StyledSectionDivider",
  componentId: "zfbbxd-3"
})(["display:none;@media screen and (min-width:", "){display:block;}"], ({
  theme
}) => theme.mediaQueries.s);
var _default = exports.default = UnitDetail;