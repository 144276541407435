"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _link = _interopRequireDefault(require("next/link"));
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _useQueryParams = require("use-query-params");
var _searchParams = _interopRequireDefault(require("../constants/searchParams"));
var _useQueryParams2 = _interopRequireDefault(require("../hooks/useQueryParams"));
var _trip = require("../utils/trip");
var _ContentWrapper = _interopRequireDefault(require("./ContentWrapper"));
var _Carousel = _interopRequireDefault(require("./designsystem/Carousel"));
var _Tile = require("./designsystem/Tile");
var _SearchHit = _interopRequireDefault(require("./SearchHit"));
var _useAnalyticsUnitViewList = _interopRequireDefault(require("../hooks/analytics/useAnalyticsUnitViewList"));
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const UnitAlternatives = ({
  rentalUnit,
  loading
}) => {
  const [urlParams] = (0, _useQueryParams2.default)(_objectSpread(_objectSpread({}, _searchParams.default), {}, {
    withHero: _useQueryParams.BooleanParam
  }));
  const didSearch = Object.keys(urlParams).length !== 0;
  const sizes = (0, _Tile.useTileImageSizes)();
  const {
    hits = []
  } = rentalUnit?.alternativesInAccommodation ?? {};
  (0, _useAnalyticsUnitViewList.default)(hits);
  return __jsx(_react.default.Fragment, null, __jsx(_ContentWrapper.default, null, __jsx(_Carousel.default, {
    title: __jsx(_reactIntl.FormattedMessage, {
      id: "jZQ/r1",
      defaultMessage: [{
        "type": 0,
        "value": "Andere opties bij "
      }, {
        "type": 1,
        "value": "name"
      }],
      values: {
        name: rentalUnit.accommodation.name
      }
    }),
    continuous: true
  }, hits.length > 0 && hits.map(hit => __jsx(SearchHitWrapper, {
    key: hit.rentalUnit.id
  }, __jsx(_SearchHit.default, {
    loading: loading,
    rentalUnit: hit.rentalUnit,
    trips: hit.trips,
    showPrice: didSearch,
    unitParams: (0, _trip.pickBookingFromParams)(urlParams),
    sizes: sizes
  })))), rentalUnit.accommodation.hasPublicPage && __jsx(StyledContentWrapper, null, __jsx(_link.default, {
    href: `/complex/${rentalUnit.accommodation.slug}`,
    passHref: true,
    legacyBehavior: true
  }, __jsx(_Button.default, {
    as: "a",
    style: {
      cursor: 'pointer'
    },
    variant: "outline"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "110NwM",
    defaultMessage: [{
      "type": 0,
      "value": "Bekijk "
    }, {
      "type": 1,
      "value": "name"
    }],
    values: {
      name: rentalUnit.accommodation.name
    }
  }))))));
};
var _default = exports.default = UnitAlternatives;
const StyledContentWrapper = (0, _styledComponents.default)(_ContentWrapper.default).withConfig({
  displayName: "UnitAlternatives__StyledContentWrapper",
  componentId: "sc-18ijbv2-0"
})(["padding-top:0;display:flex;--wrapperPadding:0;"]);
const SearchHitWrapper = _styledComponents.default.div.withConfig({
  displayName: "UnitAlternatives__SearchHitWrapper",
  componentId: "sc-18ijbv2-1"
})(["display:flex;flex-direction:column;margin-right:", ";width:32rem;scroll-snap-align:start;@media (min-width:", "){width:44.8rem;height:100%;}"], ({
  theme
}) => theme.spacing['50_Semi'], ({
  theme
}) => theme.mediaQueries.m);