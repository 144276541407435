"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const baseUrls = {
  oberon: process.env.NEXT_PUBLIC_BASE_URL_OBERON,
  texel: process.env.NEXT_PUBLIC_BASE_URL_TEXEL,
  ameland: process.env.NEXT_PUBLIC_BASE_URL_AMELAND,
  schier: process.env.NEXT_PUBLIC_BASE_URL_SCHIER,
  waterland: process.env.NEXT_PUBLIC_BASE_URL_WATERLAND,
  uitinderegio: process.env.NEXT_PUBLIC_BASE_URL_UITINDEREGIO,
  terschelling: process.env.NEXT_PUBLIC_BASE_URL_TERSCHELLING
};
var _default = exports.default = baseUrls;