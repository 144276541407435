"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.unitOwnerFragment = exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = _interopRequireDefault(require("react"));
var _client = require("@apollo/client");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _reactIntl = require("react-intl");
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _Cell = _interopRequireDefault(require("./designsystem/Cell"));
var _Grid = _interopRequireDefault(require("./designsystem/Grid"));
var _createImgProps = _interopRequireDefault(require("../utils/createImgProps"));
var _Img = _interopRequireDefault(require("./Img"));
var _devicesize = require("@oberoninternal/travelbase-ds/context/devicesize");
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const unitOwnerFragment = exports.unitOwnerFragment = (0, _client.gql)`
    fragment UnitOwner on Accommodation {
        jpegOwner: ownerImage {
            transform(config: AVATAR, format: JPEG) {
                ...Transform
            }
        }
        webpOwner: ownerImage {
            transform(config: AVATAR, format: WEBP) {
                ...Transform
            }
        }
        ownerIntroduction
        ownerName
        ownerTips
    }
`;
const UnitOwner = ({
  data: {
    ownerTips,
    ownerName,
    ownerIntroduction,
    jpegOwner,
    webpOwner
  },
  hasReviews
}) => {
  const deviceSize = (0, _devicesize.useDeviceSize)();
  const imgProps = jpegOwner && webpOwner && (0, _createImgProps.default)(jpegOwner, webpOwner);
  const owner = imgProps && __jsx(OwnerImgWrapper, {
    hasReviews: hasReviews
  }, __jsx("div", null, __jsx(_Img.default, (0, _extends2.default)({
    layout: "fill",
    loading: "lazy"
  }, _objectSpread(_objectSpread({}, imgProps), {}, {
    fallback: _objectSpread(_objectSpread({}, imgProps.fallback), {}, {
      alt: ownerName ?? ''
    })
  })))));
  const gridDisplay = deviceSize !== 'mobile' && (hasReviews && deviceSize === 'tablet' || !hasReviews);
  return __jsx(_Cell.default, {
    pb: 22,
    pt: [5, 0],
    gridColumn: ['1 / -1', null, null, `${hasReviews ? '7 / -1' : '1 / -1'}`, null, `${hasReviews ? '7 / -1' : '1 / -1'}`]
  }, __jsx(Container, null, __jsx(OwnerContainer, {
    gridDisplay: gridDisplay,
    hasReviews: hasReviews
  }, gridDisplay && owner && __jsx(_Cell.default, {
    gridColumn: [null, null, '1 / 3', '2 / 5', '2 / 5', '2 / 5']
  }, owner), __jsx(_Cell.default, {
    width: 1,
    gridColumn: ['1 / -1', '1 / -1', '3 / -1', `${hasReviews ? '1 / 7' : '6 / -1'}`]
  }, __jsx(OwnerMain, null, __jsx("div", null, __jsx(StyledTitle, {
    hasReviews: hasReviews,
    variant: "large"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "Rhuao9",
    defaultMessage: [{
      "type": 0,
      "value": "Verhuurd door"
    }]
  }), ' '), __jsx(StyledOwnerName, {
    hasReviews: hasReviews,
    variant: "large"
  }, ownerName)), !gridDisplay && owner), ownerIntroduction && __jsx(OwnerIntroduction, null, __jsx(OwnerIntroductionTitle, null, __jsx(_reactIntl.FormattedMessage, {
    id: "wzmMDW",
    defaultMessage: [{
      "type": 0,
      "value": "Even voorstellen"
    }]
  })), __jsx(_Body.default, null, ownerIntroduction)), ownerTips && __jsx(OwnerTips, null, __jsx(OwnerTipsTitle, null, __jsx(_reactIntl.FormattedMessage, {
    id: "0QNHTR",
    defaultMessage: [{
      "type": 0,
      "value": "Tips van de eigenaar"
    }]
  })), __jsx(_Body.default, null, ownerTips))))));
};
const OwnerIntroduction = _styledComponents.default.div.withConfig({
  displayName: "UnitOwner__OwnerIntroduction",
  componentId: "xww4qt-0"
})(["margin-top:", ";"], ({
  theme
}) => theme.spacing['50_Semi']);
const OwnerIntroductionTitle = (0, _styledComponents.default)(_Title.default).withConfig({
  displayName: "UnitOwner__OwnerIntroductionTitle",
  componentId: "xww4qt-1"
})(["margin-bottom:1.2rem;font-weight:500;@media (min-width:", "){margin-bottom:2rem;}"], ({
  theme
}) => theme.mediaQueries.s);
const OwnerTips = _styledComponents.default.div.withConfig({
  displayName: "UnitOwner__OwnerTips",
  componentId: "xww4qt-2"
})(["margin-top:", ";@media (min-width:", "){margin-top:", ";}"], ({
  theme
}) => theme.spacing['70_XLarge'], ({
  theme
}) => theme.mediaQueries.s, ({
  theme
}) => theme.spacing['50_Semi']);
const OwnerTipsTitle = (0, _styledComponents.default)(OwnerIntroductionTitle).withConfig({
  displayName: "UnitOwner__OwnerTipsTitle",
  componentId: "xww4qt-3"
})([""]);
const Container = _styledComponents.default.div.withConfig({
  displayName: "UnitOwner__Container",
  componentId: "xww4qt-4"
})(["display:flex;flex-direction:column;"]);
const OwnerContainer = (0, _styledComponents.default)(_Grid.default).withConfig({
  displayName: "UnitOwner__OwnerContainer",
  componentId: "xww4qt-5"
})(["display:", ";align-items:center;position:relative;@media screen and (min-width:", "px){padding-top:", ";}@media screen and (min-width:", "px){padding-top:0;}@media screen and (min-width:", "px){padding-top:0;max-width:", ";margin-left:auto;}"], ({
  gridDisplay
}) => gridDisplay ? 'grid' : 'flex', ({
  theme
}) => theme.mediaQueriesValues.xs, ({
  hasReviews
}) => hasReviews ? '5rem' : '0', ({
  theme
}) => theme.mediaQueriesValues.m, ({
  theme
}) => theme.mediaQueriesValues.l, ({
  hasReviews
}) => hasReviews ? '56.8rem' : 'unset');
const OwnerMain = _styledComponents.default.div.withConfig({
  displayName: "UnitOwner__OwnerMain",
  componentId: "xww4qt-6"
})(["display:flex;align-items:center;@media (min-width:", "){align-items:flex-start;}justify-content:space-between;width:100%;"], ({
  theme
}) => theme.mediaQueries.l);
const StyledTitle = (0, _styledComponents.default)(_Title.default).withConfig({
  displayName: "UnitOwner__StyledTitle",
  componentId: "xww4qt-7"
})(["font-weight:300;@media (min-width:", "){display:inline;}@media (min-width:", "){display:", ";}"], ({
  theme
}) => theme.mediaQueries.s, ({
  theme
}) => theme.mediaQueries.m, ({
  hasReviews
}) => hasReviews ? 'block' : 'inline');
const StyledOwnerName = (0, _styledComponents.default)(_Title.default).withConfig({
  displayName: "UnitOwner__StyledOwnerName",
  componentId: "xww4qt-8"
})(["margin:0;@media (min-width:", "){display:inline;margin-top:", ";}@media (min-width:", "){display:", ";}"], ({
  theme
}) => theme.mediaQueries.s, ({
  theme
}) => theme.spacing['30_Small'], ({
  theme
}) => theme.mediaQueries.m, ({
  hasReviews
}) => hasReviews ? 'block' : 'inline');
const OwnerImgWrapper = _styledComponents.default.div.withConfig({
  displayName: "UnitOwner__OwnerImgWrapper",
  componentId: "xww4qt-9"
})(["> div{position:relative;padding-bottom:100%;width:100%;> div{border-radius:50%;overflow:hidden;}img{object-fit:cover;}}padding-left:0.8rem;width:100%;max-width:", ";right:0;@media (min-width:", "){max-width:8rem;}@media screen and (min-width:", "){max-width:20rem;}@media (min-width:", "){max-width:", ";}@media screen and (min-width:", "){> div{> figure{margin-top:", ";}}}@media screen and (min-width:", "){max-width:", ";}@media screen and (min-width:", "){max-width:", ";}"], ({
  hasReviews
}) => hasReviews ? '5rem' : '7rem', ({
  theme
}) => theme.mediaQueries.xs, ({
  theme
}) => theme.mediaQueries.s, ({
  theme
}) => theme.mediaQueries.m, ({
  hasReviews
}) => hasReviews ? '10.4rem' : '35rem', ({
  theme
}) => theme.mediaQueries.l, ({
  hasReviews
}) => hasReviews ? '-1.6rem' : 0, ({
  theme
}) => theme.mediaQueries.xl, ({
  hasReviews
}) => hasReviews ? '10.4rem' : 'unset', ({
  theme
}) => theme.mediaQueries.xxl, ({
  hasReviews
}) => hasReviews ? '10.4rem' : 'unset');
var _default = exports.default = UnitOwner;