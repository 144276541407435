"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _react = _interopRequireDefault(require("react"));
var _TripPlannerModal = _interopRequireDefault(require("./TripPlannerModal"));
const _excluded = ["onClose", "open", "name", "id", "reserving", "maxOccupancy", "petsAllowed", "onBookingReservation"];
var __jsx = _react.default.createElement;
const UnitDetailsPlannerModal = _ref => {
  let {
      onClose,
      open,
      name,
      id,
      reserving,
      maxOccupancy,
      petsAllowed,
      onBookingReservation
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  return __jsx(_TripPlannerModal.default, (0, _extends2.default)({
    maxOccupancy: maxOccupancy,
    petsAllowed: petsAllowed,
    id: id,
    name: name,
    open: open,
    onClose: onClose,
    onSubmit: onBookingReservation
  }, props));
};
var _default = exports.default = UnitDetailsPlannerModal;