"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _reactIntl = require("react-intl");
var _Cell = _interopRequireDefault(require("./designsystem/Cell"));
var _Heading = _interopRequireDefault(require("./designsystem/Heading"));
var _TenantContext = require("../context/TenantContext");
var _image = _interopRequireDefault(require("next/image"));
var _devicesize = require("@oberoninternal/travelbase-ds/context/devicesize");
var _Grid = _interopRequireDefault(require("./designsystem/Grid"));
var __jsx = _react.default.createElement;
const UnitLocationDescription = ({
  hasReviews,
  brand
}) => {
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const {
    locale
  } = (0, _reactIntl.useIntl)();
  const deviceSize = (0, _devicesize.useDeviceSize)();
  const nativeLocationDescription = brand && brandConfig.locationDescription?.useNativeLocationDescription ? brandConfig.locationDescription.locationDescriptionByBrand?.[brand] : brandConfig.locationDescription;
  const {
    imgAlt,
    tips,
    imgSrc,
    description
  } = nativeLocationDescription ?? {};
  if (!imgAlt || !tips || !imgSrc || !description) {
    return null;
  }
  const tipsImage = __jsx(ImageContainer, {
    className: !hasReviews ? 'tips-image-container' : ''
  }, __jsx(_image.default, {
    objectFit: "cover",
    layout: "fill",
    src: imgSrc,
    alt: imgAlt[locale],
    loading: "lazy"
  }));
  const gridDisplay = deviceSize !== 'mobile' && (hasReviews && deviceSize === 'tablet' || !hasReviews);
  return __jsx(_Cell.default, {
    pb: 22,
    pt: [5, 0],
    gridColumn: ['1 / -1', null, null, `${hasReviews ? '7 / -1' : '1 / -1'}`, null, `${hasReviews ? '9 / -1' : '1 / -1'}`]
  }, __jsx(Container, null, __jsx(TipsContainer, {
    gridDisplay: gridDisplay,
    hasReviews: hasReviews
  }, gridDisplay && tipsImage && __jsx(_Cell.default, {
    gridColumn: [null, null, '1 / 3', '1 / 5', '1 / 5', '1 / 5']
  }, tipsImage), __jsx(_Cell.default, {
    alignSelf: hasReviews ? 'initial' : 'center',
    width: 1,
    gridColumn: ['1 / -1', '1 / -1', '3 / -1', `${hasReviews ? '1 / 7' : '6 / -1'}`]
  }, __jsx(_Heading.default, null, __jsx(_Title.default, {
    variant: "large"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "2r3zOA",
    defaultMessage: [{
      "type": 0,
      "value": "Tips voor uw verblijf"
    }]
  }))), !gridDisplay && tipsImage, __jsx(_Body.default, {
    dangerouslySetInnerHTML: {
      __html: description[locale]
    }
  }), __jsx(Tips, null, __jsx(_reactIntl.FormattedMessage, {
    id: "VqehB8",
    defaultMessage: [{
      "type": 0,
      "value": "Wij geven u graag 5 'geheime tips':"
    }]
  }), __jsx(List, null, tips[locale].map((tip, i) => __jsx(ListItem, {
    key: tip
  }, __jsx(_Body.default, null, __jsx(TipCount, null, __jsx(_reactIntl.FormattedMessage, {
    id: "4j1TGZ",
    defaultMessage: [{
      "type": 0,
      "value": "Tip"
    }]
  }), " ", i + 1), ' ', __jsx("span", {
    dangerouslySetInnerHTML: {
      __html: tip
    }
  }))))))))));
};
const Container = _styledComponents.default.div.withConfig({
  displayName: "UnitLocationDescription__Container",
  componentId: "duswa9-0"
})(["height:100%;"]);
const TipsContainer = (0, _styledComponents.default)(_Grid.default).withConfig({
  displayName: "UnitLocationDescription__TipsContainer",
  componentId: "duswa9-1"
})(["display:", ";position:relative;@media screen and (min-width:", "px){padding-top:", ";}@media screen and (min-width:", "px){padding-top:0;}"], ({
  gridDisplay
}) => gridDisplay ? 'grid' : 'flex', ({
  theme
}) => theme.mediaQueriesValues.xs, ({
  hasReviews
}) => hasReviews ? '5rem' : '0', ({
  theme
}) => theme.mediaQueriesValues.m);
const ImageContainer = _styledComponents.default.div.withConfig({
  displayName: "UnitLocationDescription__ImageContainer",
  componentId: "duswa9-2"
})(["::after{padding-top:calc(9 / 16 * 100%);content:'';display:block;}position:relative;margin-bottom:", ";@media (min-width:", "){max-width:'100%';}"], ({
  theme
}) => theme.spacing['50_Semi'], ({
  theme
}) => theme.mediaQueries.m);
const Tips = _styledComponents.default.div.withConfig({
  displayName: "UnitLocationDescription__Tips",
  componentId: "duswa9-3"
})(["margin-top:2.7rem;"]);
const List = _styledComponents.default.div.withConfig({
  displayName: "UnitLocationDescription__List",
  componentId: "duswa9-4"
})(["list-style:none;margin-top:2.7rem;"]);
const TipCount = _styledComponents.default.span.withConfig({
  displayName: "UnitLocationDescription__TipCount",
  componentId: "duswa9-5"
})(["font-weight:bold;"]);
const ListItem = _styledComponents.default.div.withConfig({
  displayName: "UnitLocationDescription__ListItem",
  componentId: "duswa9-6"
})([""]);
var _default = exports.default = UnitLocationDescription;