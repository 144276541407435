"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _LabelButton = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/LabelButton"));
const MediaButton = (0, _styledComponents.default)(_LabelButton.default).withConfig({
  displayName: "MediaButton",
  componentId: "e7grs0-0"
})(["height:4.8rem;padding-left:2.4rem;padding-right:2.4rem;font-size:1.6rem;.button-icon{font-size:2.4rem;margin-right:0.8rem;}@media (max-width:", "){height:32px;background:rgba(0,0,0,0.5);border-radius:0.8rem;padding-left:1rem;padding-right:1rem;font-size:1.3rem;.button-icon{font-size:1.6rem;margin-right:0.4rem;}}"], ({
  theme
}) => theme.mediaQueries.xs);
var _default = exports.default = MediaButton;