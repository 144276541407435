"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _TenantContext = require("../context/TenantContext");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const RATING_BOUNDS = {
  worstRating: 1,
  bestRating: 10
};
const useUnitSchema = rentalUnit => {
  const {
    supportedLanguages
  } = (0, _TenantContext.useTenantContext)();
  const hasReviews = rentalUnit?.reviews && rentalUnit?.reviewStats && rentalUnit?.reviews?.length > 0;
  const aggregateRating = hasReviews ? _objectSpread({
    '@type': 'AggregateRating',
    ratingValue: rentalUnit?.reviewStats?.average,
    reviewCount: rentalUnit?.reviewStats?.count
  }, RATING_BOUNDS) : undefined;
  const review = hasReviews ? rentalUnit?.reviews.map(({
    date,
    title,
    comment,
    average
  }) => ({
    '@type': 'UserReview',
    datePublished: date,
    description: comment,
    name: title,
    author: {
      '@type': 'Person',
      name: 'unknown'
    },
    reviewRating: _objectSpread({
      '@type': 'Rating',
      ratingValue: average
    }, RATING_BOUNDS)
  })) : undefined;
  return {
    '@context': 'https://schema.org',
    // @ts-ignore
    '@type': 'LodgingBusiness',
    name: rentalUnit?.name,
    description: rentalUnit?.descriptionLayout,
    slogan: rentalUnit?.tagline,
    image: [rentalUnit?.jpegThumbnail?.transform?.src ?? '', ...(rentalUnit?.images.map(({
      jpegTile
    }) => jpegTile?.src ?? '') ?? [])].filter(img => !!img),
    address: {
      '@type': 'PostalAddress',
      addressLocality: rentalUnit?.accommodation.place,
      // hardcoded, but who cares?
      addressRegion: 'Friesland',
      addressCountry: {
        '@type': 'Country',
        name: 'NLD'
      }
    },
    containedInPlace: {
      '@type': 'Accommodation',
      numberOfBedrooms: rentalUnit?.amountBedrooms ?? 0,
      petsAllowed: (rentalUnit?.petsAllowed ?? 0) > 0,
      numberOfFullBathrooms: rentalUnit?.amountBathrooms ?? 0,
      floorSize: rentalUnit?.livingArea ? {
        '@type': 'QuantitativeValue',
        unitCode: 'MTK',
        value: rentalUnit.livingArea
      } : undefined
    },
    amenityFeature: (rentalUnit?.attributeCategories ?? []).flatMap(group => group.attributes.map(attr => ({
      '@type': 'LocationFeatureSpecification',
      name: attr.name,
      value: true
    }))),
    availableLanguage: supportedLanguages,
    checkinTime: `${rentalUnit?.accommodation.checkInStartTime} - ${rentalUnit?.accommodation.checkInEndTime}`,
    checkoutTime: rentalUnit?.accommodation.checkOutTime,
    geo: {
      '@type': 'GeoCoordinates',
      latitude: rentalUnit?.accommodation.coordinates.lat,
      longitude: rentalUnit?.accommodation.coordinates.lon
    },
    aggregateRating,
    review
  };
};
var _default = exports.default = useUnitSchema;