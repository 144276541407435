"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pageQuery = exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _validInterval = _interopRequireDefault(require("@oberoninternal/travelbase-ds/utils/validInterval"));
var _client = require("@apollo/client");
var _addDays = _interopRequireDefault(require("date-fns/addDays"));
var _lodash = _interopRequireDefault(require("lodash.isequal"));
var _react = require("react");
var _parseToDateString = _interopRequireDefault(require("../constants/parseToDateString"));
var _graphql = require("../generated/graphql");
var _trip = require("../utils/trip");
var _TenantContext = require("../context/TenantContext");
var _useQueryParams = require("use-query-params");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } /* eslint-disable @typescript-eslint/no-non-null-assertion */
const pageQuery = exports.pageQuery = (0, _client.gql)`
    query UnitDetails($slug: String!, $arrivalDate: String, $bakedInFilterProperty: String!) {
        rentalUnit(slug: $slug) {
            id
            firstTripDate
            flexCancellationDays
            slug
            name
            brand
            jpegThumbnail: listImage {
                transform(config: TILE, format: JPEG) {
                    ...Transform
                }
            }
            webpThumbnail: listImage {
                transform(config: TILE, format: WEBP) {
                    ...Transform
                }
            }
            jpegHero: mainImage {
                transform(config: HERO, format: JPEG) {
                    ...Transform
                }
            }
            webpHero: mainImage {
                transform(config: HERO, format: WEBP) {
                    ...Transform
                }
            }
            municipalRegistration
            tagline
            minOccupancy
            maxOccupancy
            petsAllowed
            descriptionLayout
            descriptionParticulars
            descriptionExtras
            cancellationInsuranceAvailable
            fullCircleImageUrl
            videoUrl

            hasBakedInFilterProperty: hasFilterProperty(handle: $bakedInFilterProperty)

            surcharges(arrivalDate: $arrivalDate) {
                ...Surcharge
            }

            reviewStats {
                ...ReviewStats
            }
            ...UnitFeatures
            ...UnitPhotos

            attributeCategories {
                ...Extras
            }

            accommodation {
                id
                slug
                ...UnitAccommodation
                descriptionGeneral
                ...UnitOwner
                ...Location
                ...AccommodationPhotos

                place
                name
                checkInEndTime
                checkInStartTime
                checkOutTime
                hasPublicPage
            }

            reviews(limit: 3, offset: 0) {
                ...UnitReviews
            }
        }
    }
    query UnitDetailsSearch(
        $slug: String!
        $params: UnitSearchParamsInput
        $arrivalDate: String
        $badgeHandle: String!
        $badge2Handle: String!
    ) {
        rentalUnit(slug: $slug) {
            id
            alternativesInAccommodation(params: $params) {
                hits {
                    ...SearchHit
                }
            }
            surcharges(arrivalDate: $arrivalDate) {
                ...Surcharge
            }
        }
    }

    query UnitDetailInitialTrips(
        $slug: String!
        $start: String!
        $end: String!
        $persons: Int!
        $pets: Int!
        $babies: Int!
    ) {
        rentalUnit(slug: $slug) {
            id
            trips(pets: $pets, persons: $persons, babies: $babies, startDate: $start, endDate: $end) {
                ...PlannerTrip
            }
        }
    }

    query AlternativesInAccommodation(
        $slug: String!
        $params: UnitSearchParamsInput
        $badgeHandle: String!
        $badge2Handle: String!
    ) {
        rentalUnit(slug: $slug) {
            id
            alternativesInAccommodation(params: $params) {
                hits {
                    ...SearchHit
                }
            }
        }
    }

    fragment Surcharge on RentalUnitSurcharge {
        id
        name
        calculation
        description
        maxAmount
        minAmount
        unitPrice
    }
`;
/**
 * This hook merges the initial data with the search data after searching on the unit detail page
 */
const useUnitDetailsData = (slug, params) => {
  const initialParams = (0, _react.useRef)(params);
  const paramsHaveChanged = !(0, _lodash.default)(initialParams.current, params);
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const [{
    specialId
  }] = (0, _useQueryParams.useQueryParams)({
    specialId: _useQueryParams.StringParam
  });
  const unitDetails = (0, _graphql.useUnitDetailsQuery)({
    variables: {
      slug,
      arrivalDate: initialParams.current?.date,
      bakedInFilterProperty: brandConfig.bakedInFilterProperty ?? ''
    }
  });
  const accommodationAlternatives = (0, _graphql.useAlternativesInAccommodationQuery)({
    variables: {
      slug,
      params: initialParams.current,
      badgeHandle: brandConfig.badge?.handle ?? '',
      badge2Handle: brandConfig.badge2?.handle ?? ''
    },
    ssr: false
  });
  const search = (0, _graphql.useUnitDetailsSearchQuery)({
    skip: !paramsHaveChanged,
    ssr: false,
    variables: {
      slug,
      params,
      arrivalDate: params?.date,
      badgeHandle: brandConfig.badge?.handle ?? '',
      badge2Handle: brandConfig.badge2?.handle ?? ''
    }
  });
  const {
    babies,
    date,
    duration,
    persons,
    pets
  } = initialParams.current ?? {};
  const initialTrips = (0, _graphql.useUnitDetailInitialTripsQuery)({
    variables: {
      slug,
      babies: babies ?? 0,
      persons: persons ?? 2,
      pets: pets ?? 0,
      start: date,
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      end: (0, _parseToDateString.default)((0, _addDays.default)(new Date(date || new Date()), duration || 0))
    },
    ssr: false,
    skip: !date || !duration
  });
  const initialTrip = (0, _react.useMemo)(() => {
    if (!initialTrips.data?.rentalUnit?.trips) {
      return undefined;
    }
    const period = (0, _trip.convertToPeriod)(initialTrips.variables?.start, initialTrips.variables?.end);
    if (!(0, _validInterval.default)(period)) {
      return undefined;
    }
    const trips = (0, _trip.getTripsFromPeriod)(period, initialTrips.data.rentalUnit.trips);

    // If there is a specialId provided in the params, return the trip that matches the specialId
    if (specialId) {
      const tripThatMatchesSpecialId = trips.find(item => item.specialId === specialId);
      if (tripThatMatchesSpecialId) {
        return tripThatMatchesSpecialId;
      }
    }
    return trips[0];
    // we only want to change this reference on the basis of the initial trips, thus when loading is done
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialTrips.variables?.end, initialTrips.variables?.start, initialTrips.loading]);
  const rentalUnitData = (0, _react.useMemo)(() => {
    if (!unitDetails.data?.rentalUnit) {
      return unitDetails.data?.rentalUnit;
    }
    if (search.data?.rentalUnit) {
      return _objectSpread(_objectSpread({}, unitDetails.data?.rentalUnit), {}, {
        alternativesInAccommodation: search.data?.rentalUnit.alternativesInAccommodation,
        surcharges: search.data.rentalUnit.surcharges
      });
    }
    return _objectSpread(_objectSpread({}, unitDetails.data?.rentalUnit), {}, {
      alternativesInAccommodation: accommodationAlternatives.data?.rentalUnit?.alternativesInAccommodation
    });
  }, [accommodationAlternatives.data, search.data, unitDetails.data]);
  return {
    data: {
      rentalUnit: rentalUnitData,
      initialTrip
    },
    loading: unitDetails.loading,
    loadingAlternatives: search.loading,
    loadingInitialTrip: initialTrips.loading
  };
};
var _default = exports.default = useUnitDetailsData;