"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _Modal = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/Modal"));
var _ModalHeader = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/ModalHeader"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _useSesame = _interopRequireDefault(require("@oberoninternal/travelbase-ds/hooks/useSesame"));
var _grid = require("@rebass/grid");
var _react = _interopRequireWildcard(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _surchargeCalculationTexts = _interopRequireDefault(require("../constants/surchargeCalculationTexts"));
var _graphql = require("../generated/graphql");
var _Heading = _interopRequireDefault(require("./designsystem/Heading"));
var _ContentWrapper = _interopRequireDefault(require("./ContentWrapper"));
var _TextClamp = _interopRequireDefault(require("./designsystem/TextClamp"));
var _messages = _interopRequireDefault(require("../constants/messages"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const Surcharge = ({
  surcharge: {
    name,
    description,
    unitPrice,
    calculation
  }
}) => __jsx(_grid.Flex, {
  key: name,
  justifyContent: "space-between"
}, __jsx(_grid.Box, {
  flex: 1
}, name, __jsx(_Body.default, {
  variant: "small"
}, description)), __jsx(_grid.Box, {
  ml: 3
}, __jsx(PriceBody, {
  variant: "large"
}, calculation !== _graphql.SurchargeCalculationEnum.Subsequent && __jsx(_react.default.Fragment, null, __jsx(_reactIntl.FormattedNumber, {
  value: unitPrice,
  format: "EUR"
}), __jsx("br", null)), _surchargeCalculationTexts.default[calculation])));
const UnitParticulars = ({
  data: {
    descriptionParticulars,
    accommodation: {
      checkInStartTime,
      checkInEndTime,
      checkOutTime
    },
    surcharges,
    flexCancellationDays
  }
}) => {
  const {
    onClose,
    open,
    onOpen
  } = (0, _useSesame.default)();
  const intl = (0, _reactIntl.useIntl)();
  const {
    0: ruOptions,
    1: ruSurcharges
  } = (0, _react.useMemo)(() => {
    const opts = [];
    const sur = [];
    for (const surcharge of surcharges || []) {
      if (surcharge) {
        if (surcharge.minAmount === 1 && surcharge.maxAmount === 1) {
          sur.push(surcharge);
        } else {
          opts.push(surcharge);
        }
      }
    }
    return [opts, sur];
  }, [surcharges]);
  return __jsx(_react.default.Fragment, null, __jsx(_ContentWrapper.default, null, __jsx(Content, null, __jsx(_Heading.default, null, __jsx(_Title.default, {
    variant: "large",
    elementType: "h2"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "7iaeht",
    defaultMessage: [{
      "type": 0,
      "value": "Belangrijk om te weten"
    }]
  }))), descriptionParticulars && __jsx(_TextClamp.default, {
    variant: "regular",
    maxLength: 200,
    text: descriptionParticulars
  }), __jsx(_grid.Box, {
    p: 4
  }, checkInStartTime === checkInEndTime ? __jsx(Item, {
    label: __jsx(_reactIntl.FormattedMessage, {
      id: "iU4gWy",
      defaultMessage: [{
        "type": 0,
        "value": "Check in:"
      }]
    })
  }, checkInStartTime, __jsx(_reactIntl.FormattedMessage, {
    id: "ViGBFO",
    defaultMessage: [{
      "type": 0,
      "value": "uur"
    }]
  })) : __jsx(Item, {
    label: __jsx(_reactIntl.FormattedMessage, {
      id: "5+NR6j",
      defaultMessage: [{
        "type": 0,
        "value": "Check in tussen:"
      }]
    })
  }, checkInStartTime, " ", __jsx(_reactIntl.FormattedMessage, {
    id: "ViGBFO",
    defaultMessage: [{
      "type": 0,
      "value": "uur"
    }]
  }), ' - ', checkInEndTime, " ", __jsx(_reactIntl.FormattedMessage, {
    id: "ViGBFO",
    defaultMessage: [{
      "type": 0,
      "value": "uur"
    }]
  })), __jsx(Item, {
    label: __jsx(_reactIntl.FormattedMessage, {
      id: "ESPp+x",
      defaultMessage: [{
        "type": 0,
        "value": "Check uit voor:"
      }]
    })
  }, checkOutTime, " ", __jsx(_reactIntl.FormattedMessage, {
    id: "ViGBFO",
    defaultMessage: [{
      "type": 0,
      "value": "uur"
    }]
  }))), flexCancellationDays !== null && __jsx(StyledBox, {
    p: 4
  }, __jsx(Item, {
    label: intl.formatMessage(_messages.default.flexCancellation)
  }, __jsx(_grid.Box, {
    pb: 2
  }, __jsx(_Body.default, {
    style: {
      fontWeight: 600
    }
  }, intl.formatMessage(_messages.default.flexCancellationUntil, {
    flexCancellationDays
  })), __jsx(_Body.default, {
    style: {
      paddingTop: '0.8rem'
    }
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "/qzNZ5",
    defaultMessage: [{
      "type": 0,
      "value": "Gratis annuleren kan bij deze accommodatie tot "
    }, {
      "type": 1,
      "value": "flexCancellationDays"
    }, {
      "type": 0,
      "value": " dagen voor aankomst. Eventuele reserveringskosten en verzekeringspremie worden bij annulering altijd in rekening gebracht."
    }],
    values: {
      flexCancellationDays
    }
  }))))), surcharges.length > 0 && __jsx(_Button.default, {
    variant: "outline",
    onClick: onOpen
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "EdUKLe",
    defaultMessage: [{
      "type": 0,
      "value": "Opties en bijkomende kosten"
    }]
  })))), __jsx(_Modal.default, {
    onClose: onClose,
    open: open,
    variant: "regular"
  }, __jsx(_ModalHeader.default, {
    variant: "regular"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "EdUKLe",
    defaultMessage: [{
      "type": 0,
      "value": "Opties en bijkomende kosten"
    }]
  })), __jsx(Surcharges, null, __jsx(_Body.default, null, __jsx(_reactIntl.FormattedMessage, {
    id: "feEeXy",
    defaultMessage: [{
      "type": 0,
      "value": "Eventuele opties kun je toevoegen in stap 1 van het boeken van deze accommodatie"
    }]
  })), ruSurcharges.length > 0 && __jsx(SubHeader, null, __jsx(_reactIntl.FormattedMessage, {
    id: "VtKuYt",
    defaultMessage: [{
      "type": 0,
      "value": "Verplichte bijkomende kosten"
    }]
  })), ruSurcharges.map(surcharge => __jsx(Surcharge, {
    key: surcharge.name,
    surcharge: surcharge
  })), ruOptions.length > 0 && __jsx(SubHeader, null, __jsx(_reactIntl.FormattedMessage, {
    id: "nI1QQ6",
    defaultMessage: [{
      "type": 0,
      "value": "Optioneel"
    }]
  })), ruOptions.map(surcharge => __jsx(Surcharge, {
    key: surcharge.name,
    surcharge: surcharge
  })))));
};
var _default = exports.default = UnitParticulars;
const PriceBody = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "UnitParticulars__PriceBody",
  componentId: "em4yuj-0"
})(["text-align:right;"]);
const Surcharges = _styledComponents.default.div.withConfig({
  displayName: "UnitParticulars__Surcharges",
  componentId: "em4yuj-1"
})(["margin-top:", ";> div + div{margin-top:", ";}"], ({
  theme
}) => theme.spacing['40_Standard'], ({
  theme
}) => theme.spacing['40_Standard']);
const Item = ({
  label,
  children
}) => __jsx(_grid.Flex, {
  flex: 1
}, __jsx(_grid.Box, {
  width: "18rem"
}, __jsx(Label, null, label)), __jsx(_grid.Flex, {
  flex: 1
}, children));
const Label = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "UnitParticulars__Label",
  componentId: "em4yuj-2"
})(["font-weight:600;"]);
const Content = (0, _styledComponents.default)(_grid.Box).attrs({
  width: 1
}).withConfig({
  displayName: "UnitParticulars__Content",
  componentId: "em4yuj-3"
})(["max-width:80.8rem;> * + *{margin-top:", ";@media (min-width:", "){margin-top:", ";}}"], ({
  theme
}) => theme.spacing['40_Standard'], ({
  theme
}) => theme.mediaQueries.l, ({
  theme
}) => theme.spacing['50_Semi']);
const SubHeaderBody = (0, _styledComponents.default)(_Body.default).attrs({
  variant: 'large'
}).withConfig({
  displayName: "UnitParticulars__SubHeaderBody",
  componentId: "em4yuj-4"
})(["font-weight:600;"]);
const SubHeaderContainer = _styledComponents.default.div.withConfig({
  displayName: "UnitParticulars__SubHeaderContainer",
  componentId: "em4yuj-5"
})(["width:100%;border-bottom:1px solid ", ";padding-bottom:1.6rem;margin-top:2.4rem !important;"], ({
  theme
}) => theme.colors.neutral['20']);
const SubHeader = ({
  children
}) => __jsx(SubHeaderContainer, null, __jsx(SubHeaderBody, null, children));
const StyledBox = (0, _styledComponents.default)(_grid.Box).withConfig({
  displayName: "UnitParticulars__StyledBox",
  componentId: "em4yuj-6"
})(["background:", ";border-radius:", ";@media screen and (max-width:", "){> div:first-of-type{flex-wrap:wrap;> div:first-of-type{width:100%;}}}"], ({
  theme
}) => theme.colors.neutral['10'], ({
  theme
}) => theme.radius.textInput, ({
  theme
}) => theme.mediaQueries.s);