"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _router = require("next/router");
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _filterTypes = require("../constants/filterTypes");
var _getUnitTypeConsts = _interopRequireDefault(require("../constants/getUnitTypeConsts"));
var _TenantContext = require("../context/TenantContext");
var _Breadcrumbs = _interopRequireDefault(require("./Breadcrumbs"));
var __jsx = _react.default.createElement;
const UnitBreadCrumbs = ({
  data: {
    type,
    name
  }
}) => {
  const intl = (0, _reactIntl.useIntl)();
  const router = (0, _router.useRouter)();
  const locale = router.locale;
  const hasSearched = !!router.query.searched;
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const homepageUrl = brandConfig.homepageUrl?.[locale];
  const relevantAccType = brandConfig.accommodationTypes.find(option => option.value.includes(_filterTypes.rentalUnitTypes[type]))?.value ?? 'all';
  const searchPathname = brandConfig.searchUri?.[router.locale] ?? `/search`;
  const searchResultsHref = {
    pathname: searchPathname,
    search: router.query.searched
  };
  return __jsx(_Breadcrumbs.default, null, hasSearched && __jsx(_Breadcrumbs.default.PrevLink, {
    href: searchResultsHref
  }), __jsx(_Breadcrumbs.default.Crumb, {
    className: "gt-s",
    href: homepageUrl ?? {
      pathname: `/`
    }
  }, "Home"), hasSearched && __jsx(_Breadcrumbs.default.Crumb, {
    className: "gt-s",
    href: searchResultsHref
  }, "Zoekresultaten"), !hasSearched && __jsx(_Breadcrumbs.default.Crumb, {
    className: "gt-s",
    href: {
      pathname: searchPathname,
      query: {
        accommodationType: relevantAccType
      }
    }
  }, relevantAccType ? (0, _getUnitTypeConsts.default)(intl)[type].translation : __jsx(_reactIntl.FormattedMessage, {
    id: "atSMJi",
    defaultMessage: [{
      "type": 0,
      "value": "Alle accommodaties"
    }]
  })), __jsx(_Breadcrumbs.default.Crumb, {
    active: true,
    className: "gt-s"
  }, name));
};
var _default = exports.default = UnitBreadCrumbs;