"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.unitRelevantAlternativesQuery = exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _grid = require("@rebass/grid");
var _client = require("@apollo/client");
var _lodash = _interopRequireDefault(require("lodash.range"));
var _router = require("next/router");
var _react = _interopRequireWildcard(require("react"));
var _reactIntersectionObserver = require("react-intersection-observer");
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _searchParams = _interopRequireDefault(require("../constants/searchParams"));
var _graphql = require("../generated/graphql");
var _useQueryParams = _interopRequireDefault(require("../hooks/useQueryParams"));
var _trip = require("../utils/trip");
var _Carousel = _interopRequireDefault(require("./designsystem/Carousel"));
var _Tile = require("./designsystem/Tile");
var _UnitDetail = require("./pages/UnitDetail");
var _SearchHit = _interopRequireDefault(require("./SearchHit"));
var _useAnalyticsUnitViewList = _interopRequireDefault(require("../hooks/analytics/useAnalyticsUnitViewList"));
var _ContentWrapper = _interopRequireDefault(require("./ContentWrapper"));
var _TenantContext = require("../context/TenantContext");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const unitRelevantAlternativesQuery = exports.unitRelevantAlternativesQuery = (0, _client.gql)`
    query UnitRelevantAlternatives(
        $slug: String!
        $params: UnitSearchParamsInput
        $badgeHandle: String!
        $badge2Handle: String!
    ) {
        rentalUnit(slug: $slug) {
            id

            relevantAlternatives(params: $params) {
                ...UnitRelevantAlternatives
            }
        }
    }

    fragment UnitRelevantAlternatives on UnitSearchResult {
        hits {
            ...SearchHit
        }
    }
`;
const UnitRelevantAlternatives = ({
  params
}) => {
  const [query] = (0, _useQueryParams.default)(_searchParams.default);
  const router = (0, _router.useRouter)();
  const sizes = (0, _Tile.useTileImageSizes)();
  const [ref, inView] = (0, _reactIntersectionObserver.useInView)({
    triggerOnce: true
  });
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const realParams = params?.date && params.duration ? _objectSpread(_objectSpread({}, params), brandConfig.bakedInFilterProperty ? {
    filters: [...(params.filters ?? []), {
      properties: ['AND', brandConfig.bakedInFilterProperty]
    }]
  } : {}) : null;
  const {
    data,
    loading
  } = (0, _graphql.useUnitRelevantAlternativesQuery)({
    skip: !inView,
    variables: {
      params: realParams,
      slug: router.query.slug,
      badgeHandle: brandConfig.badge?.handle ?? '',
      badge2Handle: brandConfig.badge2?.handle ?? ''
    },
    ssr: false
  });
  const unitParams = (0, _react.useMemo)(() => (0, _trip.pickBookingFromParams)(query), [query]);
  const hideAlternatives = data?.rentalUnit?.relevantAlternatives && !data.rentalUnit.relevantAlternatives.hits.length;
  (0, _useAnalyticsUnitViewList.default)(data?.rentalUnit?.relevantAlternatives?.hits ?? undefined);
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    __jsx(_UnitDetail.UnitSection, {
      ref: ref,
      name: "alternatives"
    }, !hideAlternatives && __jsx(_ContentWrapper.default, null, __jsx(_Carousel.default, {
      continuous: true,
      title: __jsx(_reactIntl.FormattedMessage, {
        id: "o39uWN",
        defaultMessage: [{
          "type": 0,
          "value": "Accommodaties die u misschien ook leuk vindt"
        }]
      })
    }, loading && (0, _lodash.default)(4).map(i => __jsx(Container, {
      key: i
    }, __jsx(Wrapper, null, __jsx(_SearchHit.default, {
      loading: true
    })))), !loading && data?.rentalUnit?.relevantAlternatives?.hits.map(hit => __jsx(Container, {
      key: hit.rentalUnit.id
    }, __jsx(Wrapper, null, __jsx(_SearchHit.default, {
      sizes: sizes,
      unitParams: unitParams,
      rentalUnit: hit.rentalUnit,
      trips: hit.trips,
      loading: false
    })))))))
  );
};
var _default = exports.default = UnitRelevantAlternatives;
const Container = (0, _styledComponents.default)(_grid.Box).withConfig({
  displayName: "UnitRelevantAlternatives__Container",
  componentId: "i6gcwm-0"
})(["scroll-snap-align:start;& + &{margin-left:3.2rem;}&:last-child{margin-right:3.2rem;}"]);
const Wrapper = (0, _styledComponents.default)(_grid.Box).withConfig({
  displayName: "UnitRelevantAlternatives__Wrapper",
  componentId: "i6gcwm-1"
})(["width:32rem;@media (min-width:", "){width:44.8rem;}> a > div{scroll-snap-align:none;}"], ({
  theme
}) => theme.mediaQueries.m);