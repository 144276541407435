"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _MediaModal = _interopRequireDefault(require("./MediaModal"));
var _reactIframe = _interopRequireDefault(require("react-iframe"));
var __jsx = _react.default.createElement;
const FullCircleModal = ({
  open,
  onClose,
  src
}) => {
  const srcUrl = src ?? '';
  return __jsx(_MediaModal.default, {
    open: open,
    onClose: onClose
  }, __jsx(_reactIframe.default, {
    url: srcUrl,
    width: "100%",
    height: "100%",
    display: "block",
    position: "relative"
  }));
};
var _default = exports.default = FullCircleModal;