"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _Separator = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/section/Separator"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _grid = require("@rebass/grid");
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _ContentWrapper = _interopRequireDefault(require("./ContentWrapper"));
var _Cell = _interopRequireDefault(require("./designsystem/Cell"));
var _Grid = _interopRequireDefault(require("./designsystem/Grid"));
var _Heading = _interopRequireDefault(require("./designsystem/Heading"));
var _Intro = _interopRequireDefault(require("./Intro"));
var _UnitFeatures = _interopRequireDefault(require("./UnitFeatures"));
var _FreeCancelation = _interopRequireDefault(require("./svg/FreeCancelation.svg"));
var _Divider = _interopRequireDefault(require("./Divider"));
var _messages = _interopRequireDefault(require("../constants/messages"));
var _createUnitDetailTitle = _interopRequireDefault(require("../utils/createUnitDetailTitle"));
var _FavoriteButton = _interopRequireDefault(require("./FavoriteButton"));
var _TenantContext = require("../context/TenantContext");
const _excluded = ["name", "tagline", "descriptionLayout", "accommodation", "reviewStats", "flexCancellationDays", "id"];
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const UnitIntro = _ref => {
  let {
      data: {
        name,
        tagline,
        descriptionLayout,
        accommodation,
        reviewStats,
        flexCancellationDays,
        id
      }
    } = _ref,
    data = (0, _objectWithoutProperties2.default)(_ref.data, _excluded);
  const intl = (0, _reactIntl.useIntl)();
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  return __jsx(StyledWrapper, null, __jsx(_Grid.default, null, __jsx(_Intro.default, {
    title: (0, _createUnitDetailTitle.default)(name, accommodation.name, accommodation.hasPublicPage),
    rating: reviewStats?.count ? reviewStats?.average : undefined,
    description: descriptionLayout,
    tagline: tagline,
    gridColumn: "1 / -1"
  }), brandConfig.enableFavorites && __jsx(_grid.Box, {
    mb: [0, null, '64px'],
    mt: [8, null, 0]
  }, __jsx(_FavoriteButton.default, {
    type: "rentalUnit",
    id: id
  })), __jsx(_Cell.default, {
    gridColumn: "1 / -1",
    style: {
      maxWidth: '102.4rem'
    }
  }, __jsx(MobileWrapper, null, __jsx(_Separator.default, null), __jsx(_Heading.default, null, __jsx(_Title.default, null, __jsx(_reactIntl.FormattedMessage, {
    id: "uXlzjt",
    defaultMessage: [{
      "type": 0,
      "value": "Eigenschappen"
    }]
  })))), __jsx(_UnitFeatures.default, {
    data: _objectSpread({
      accommodation
    }, data)
  })), flexCancellationDays !== null && __jsx(_Cell.default, {
    gridColumn: "1 / -1"
  }, __jsx(_Divider.default, {
    m: 0,
    mt: 5
  }), __jsx(Item, {
    label: intl.formatMessage(_messages.default.flexCancellation)
  }, intl.formatMessage(_messages.default.flexCancellationUntil, {
    flexCancellationDays
  }).toLowerCase()))));
};
var _default = exports.default = UnitIntro;
const StyledWrapper = (0, _styledComponents.default)(_ContentWrapper.default).withConfig({
  displayName: "UnitIntro__StyledWrapper",
  componentId: "sc-7yx1ob-0"
})(["&&{padding-top:2.4rem;@supports (padding-top:clamp(1px,1px,1px)){padding-top:clamp(1.6rem,3vw,3.6rem);}}"]);
const MobileWrapper = _styledComponents.default.div.withConfig({
  displayName: "UnitIntro__MobileWrapper",
  componentId: "sc-7yx1ob-1"
})(["width:100%;@media screen and (min-width:", "){display:none;}"], ({
  theme
}) => theme.mediaQueries.s);
const Item = ({
  label,
  children
}) => __jsx(StyledBox, {
  flex: 1,
  my: 5,
  alignItems: "center"
}, __jsx(_FreeCancelation.default, null), __jsx(_grid.Box, {
  px: 3
}, __jsx(Label, null, label, "\xA0", children)));
const StyledBox = (0, _styledComponents.default)(_grid.Flex).withConfig({
  displayName: "UnitIntro__StyledBox",
  componentId: "sc-7yx1ob-2"
})(["@media screen and (max-width:", "){align-items:start;}"], ({
  theme
}) => theme.mediaQueries.s);
const Label = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "UnitIntro__Label",
  componentId: "sc-7yx1ob-3"
})(["font-weight:600;"]);