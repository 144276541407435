"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
var _reactIntl = require("react-intl");
var _getSqzlyCategoryName = _interopRequireDefault(require("../../utils/getSqzlyCategoryName"));
var _getUnitTypeConsts = require("../../constants/getUnitTypeConsts");
var _getLanguage = _interopRequireDefault(require("../../utils/getLanguage"));
const useAnalyticsUnitView = (rentalUnit, booking) => {
  const {
    locale
  } = (0, _reactIntl.useIntl)();
  const prev = (0, _react.useRef)(null);
  (0, _react.useEffect)(() => {
    window.dataLayer = window.dataLayer ?? [];
    const shouldIgnore =
    // dates were selected, but no trip yet, so wait for that first.
    booking?.arrivalDate && booking.departureDate && !booking.trip;
    if (rentalUnit && !shouldIgnore && rentalUnit.id !== prev.current?.id) {
      prev.current = rentalUnit;
      window.dataLayer.push({
        ecommerce: null
      });
      window.dataLayer.push({
        event: 'view_item',
        ecommerce: {
          items: [{
            item_category: _getUnitTypeConsts.unitTypeMessages[rentalUnit.type].defaultMessage[0].value,
            item_id: rentalUnit.code,
            item_name: rentalUnit.name,
            item_variant: booking?.trip ? `${booking.departureDate} - ${booking.arrivalDate}` : undefined,
            price: booking?.trip?.price,
            currency: 'EUR',
            quantity: 1
          }],
          value: booking?.trip?.price
        },
        squeezelyProducts: [{
          id: rentalUnit.code,
          name: rentalUnit.name,
          category_id: rentalUnit ? (0, _getSqzlyCategoryName.default)('T', locale, rentalUnit.type) : '',
          checkin_date: booking?.arrivalDate,
          checkout_date: booking?.departureDate,
          language: (0, _getLanguage.default)(locale),
          price: booking?.trip?.price,
          quantity: 1
        }]
      });
    }
  }, [rentalUnit, booking, locale, prev]);
};
var _default = exports.default = useAnalyticsUnitView;