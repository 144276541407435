"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.query = exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _Separator = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/section/Separator"));
var _client = require("@apollo/client");
var _react = _interopRequireWildcard(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _graphql = require("../generated/graphql");
var _Cell = _interopRequireDefault(require("./designsystem/Cell"));
var _Grid = _interopRequireDefault(require("./designsystem/Grid"));
var _Review = _interopRequireDefault(require("./Review"));
var _ReviewStats = _interopRequireDefault(require("./ReviewStats"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const query = exports.query = (0, _client.gql)`
    query Reviews($slug: String!, $limit: Int!, $offset: Int) {
        rentalUnit(slug: $slug) {
            id
            reviews(limit: $limit, offset: $offset) {
                ...UnitReviews
            }
        }
    }

    fragment UnitReviews on Review {
        id
        title
        average
        city
        date
        comment
        reply
    }
`;
const INITIAL_OFFSET = 3;
const UnitReviews = ({
  reviewStats,
  slug,
  ownerImage,
  ownerName,
  className,
  reviews
}) => {
  const {
    0: isLoadMoreVisible,
    1: setLoadMoreVisible
  } = (0, _react.useState)(reviewStats && reviewStats.count > 3);
  const [fetch, {
    data: reviewData,
    loading,
    fetchMore,
    called
  }] = (0, _graphql.useReviewsLazyQuery)({
    variables: {
      slug,
      limit: 5,
      offset: INITIAL_OFFSET
    },
    notifyOnNetworkStatusChange: true
  });
  const onLoadMoreReviews = (0, _react.useCallback)(() => {
    if (loading) {
      return;
    }
    const variables = {
      offset: INITIAL_OFFSET + (reviewData?.rentalUnit?.reviews.length ?? 0)
    };
    if (!called) {
      fetch();
    } else {
      if (!fetchMore) {
        return;
      }
      fetchMore?.({
        variables,
        updateQuery: (prev, {
          fetchMoreResult
        }) => {
          if (!fetchMoreResult?.rentalUnit) {
            return prev;
          }
          const prevReviews = prev.rentalUnit?.reviews ?? [];
          const moreReviews = fetchMoreResult.rentalUnit.reviews ?? [];
          const newReviews = [...prevReviews, ...moreReviews];
          if (newReviews.length === reviewStats?.count) {
            setLoadMoreVisible(!isLoadMoreVisible);
          }
          return _objectSpread(_objectSpread({}, prev), {}, {
            rentalUnit: _objectSpread(_objectSpread(_objectSpread({}, fetchMoreResult.rentalUnit), prev.rentalUnit), {}, {
              reviews: newReviews
            })
          });
        }
      });
    }
  }, [loading, reviewData, called, fetch, fetchMore, reviewStats, isLoadMoreVisible]);
  return __jsx(_Cell.default, {
    gridColumn: ['1 / -1', null, null, 'span 5', null, 'span 6']
  }, __jsx(Container, {
    className: className
  }, __jsx(_ReviewStats.default, {
    title: __jsx(_reactIntl.FormattedMessage, {
      id: "PVD/KJ",
      defaultMessage: [{
        "type": 0,
        "value": "Beoordelingen van gasten"
      }]
    }),
    reviewStats: reviewStats
  }), __jsx(ReviewsContainer, null, [...reviews, ...(reviewData?.rentalUnit?.reviews ?? [])].map(review => __jsx(_react.Fragment, {
    key: review.id
  }, __jsx(StyledReview, {
    review: review,
    ownerImage: ownerImage,
    ownerName: ownerName
  }), __jsx(ReviewSeparator, null)))), __jsx(ButtonGrid, null, __jsx("div", null, __jsx(StyledButton, {
    variant: "outline",
    onClick: onLoadMoreReviews,
    isVisible: isLoadMoreVisible,
    submitting: loading
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "6zIbOO",
    defaultMessage: [{
      "type": 0,
      "value": "Bekijk meer beoordelingen"
    }]
  }))))));
};
const StyledReview = (0, _styledComponents.default)(_Review.default).withConfig({
  displayName: "UnitReviews__StyledReview",
  componentId: "sc-18f242t-0"
})([""]);
const ReviewSeparator = (0, _styledComponents.default)(_Separator.default).withConfig({
  displayName: "UnitReviews__ReviewSeparator",
  componentId: "sc-18f242t-1"
})(["border:none;margin:3.2rem 0;@media (min-width:", "){display:none;}&:last-child{display:none;}"], ({
  theme
}) => theme.mediaQueries.s);
const Container = _styledComponents.default.div.withConfig({
  displayName: "UnitReviews__Container",
  componentId: "sc-18f242t-2"
})(["@media (max-width:", ") and (min-width:", "){max-width:56.8rem;}"], ({
  theme
}) => theme.mediaQueries.l, ({
  theme
}) => theme.mediaQueries.m);
const ReviewsContainer = _styledComponents.default.div.withConfig({
  displayName: "UnitReviews__ReviewsContainer",
  componentId: "sc-18f242t-3"
})(["margin-top:3.2rem;> ", "{@media (min-width:", "){margin-top:5.6rem;&:first-child{margin-top:0;}}}"], StyledReview, ({
  theme
}) => theme.mediaQueries.s);
const StyledButton = (0, _styledComponents.default)(_Button.default).withConfig({
  displayName: "UnitReviews__StyledButton",
  componentId: "sc-18f242t-4"
})(["margin:7.26% 0;display:", ";"], ({
  isVisible
}) => isVisible ? 'block' : 'none');
const ButtonGrid = (0, _styledComponents.default)(_Grid.default).withConfig({
  displayName: "UnitReviews__ButtonGrid",
  componentId: "sc-18f242t-5"
})(["> div{grid-column:1 / -1;}@media (min-width:", "){> div{grid-column:2 / -1;}}"], ({
  theme
}) => theme.mediaQueries.s);
var _default = exports.default = UnitReviews;